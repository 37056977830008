import React, { FC, useState, useEffect, useCallback, } from "react";
import { useParams } from "react-router-dom";
import { routes } from "../../../../utils/constants/routes";
import axiosInstance from "../../../../utils/axios";
import AddEdit from "../../CommonPage/AddEdit";
import { NewAgentForm } from "./NewAgentForm";
import { URLS } from "../../../../utils/constants/urls";
import strings from "../../../../common/Translation/Translate";

const NewAgent: FC = () => {
  const { id } = useParams() as any;
  const [pageResponse, setPageResponse] = useState<any>([]);

  const getInitialResponse = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.editAgent(id));
      setPageResponse(data);
    } catch (error: any) {
    }
  },
    [id]
  );

  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);




  const pageConfig =
  {
    title: id ? strings.agent_title_edit : strings.agent_title_add,
    successMessage: id ? strings.common_update_message : strings.common_save_message,
    addEditApiUrl: id ? URLS.editAgent(id) : URLS.addAgent,
    initialApi: URLS.editAgent(id),
    formModel: NewAgentForm(),
    navigateToPage: routes.agentList,
    isCustomResponse: false,
  }

  return (<AddEdit pageConfig={pageConfig} pageResponse={pageResponse} />);
};
export default NewAgent;