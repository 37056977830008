import React, { FC, useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../../utils/axios";
import AddEdit from "../../../../CommonPage/AddEdit";
import { AddEditTicketSubCategoriesForm } from "./AddEditTicketSubCategoriesForm";
import { URLS } from "../../../../../../utils/constants/urls";
import strings from "../../../../../../common/Translation/Translate";
import { routes } from "../../../../../../utils/constants/routes";

const AddEditSubCategories: FC = () => {
  const { id } = useParams() as any;
  const Navigate = useNavigate();
  const params = useLocation().search as any;
  const paramsId = new URLSearchParams(params).get('id');
  const [pageResponse, setPageResponse] = useState<any>([]);
  const getInitialResponse = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(
        URLS.get_edit_delete_ticket_sub_categories(id)
      );
      setPageResponse(data);
    } catch (error: any) {}
  }, [id]);

  const handleSecondaryButton = (id: number) => {
    Navigate(`${routes.addSubCategoriesMapping}?id=${id}`)
  }
  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);

  const pageConfig = {
    title: id
      ? strings.ticket_sub_categories_title_edit
      : strings.ticket_sub_categories_title_add,
    successMessage: id
      ? strings.common_update_message
      : strings.common_save_message,
    addEditApiUrl: id
      ? URLS.get_edit_delete_ticket_sub_categories(id)
      : URLS.addTicketSubCategories(paramsId),
    initialApi: URLS.editRole(id),
    formModel: AddEditTicketSubCategoriesForm(strings),
    navigateToPage: routes.ticketSubCategories,
    isCustomResponse: false,
    secondaryButtonText: strings.title_add_multiple_sub_link
  };

  return <AddEdit pageConfig={pageConfig} pageResponse={pageResponse} handleSecondaryButton={handleSecondaryButton} />;
};
export default AddEditSubCategories;
