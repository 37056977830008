import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Typography,
  Divider,
  Container,
  AlertColor,
  CircularProgress,
} from "@mui/material";
import "./UserProfile.scss";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";
import Form, { FormDataModel } from "../../../components/Form";
import { removeErrorFieldsFromValues } from "../../../utils/validators";
import Notification from "../../../components/Notification";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { EditProfileForm } from "./EditProfileForm";
import strings from "../../../common/Translation/Translate";
import { createDropdownData, fetchIdsFromSelectedDropdownValues } from "../../../utils/validators/HelperFunctions";

const EditProfile: FC = () => {
  let editProfileForm: RefObject<Form | null | undefined> = useRef();
  const [userData, setUserData] = useState<any>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  // const [country, setCountry] = useState<any>([]);
  // const [states, setStates] = useState<any>([]);
  // const [cities, setCities] = useState<any>([]);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: "",
  });

  const getUser = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.userProfile);
      setUserData(data);
      // if (data?.country?.value) {
      //   await getState(data?.country?.value);
      // }
      // if (data?.state?.value) {
      //   await getCities(data?.state?.value);
      // }
    } catch (error: any) {
      if (error.response) {
        setMessage({
          display: true,
          severity: "warning",
          message: error.response.data.message,
        });
      }
      console.log(error);
    }
  }, []);
  const getLanguage = useCallback(async () => {
    const { data } = await axiosInstance.get(URLS.language);
    const filter = data.map((item: any) => { return ({ name: item.text, id: item.text }) });
    setLanguage(filter);
  }, [])
  const getRoles = useCallback(async () => {
    const body = { page: 1, size: 1000, filter: [], sort: ['createdAt:desc'] };
    const { data } = await axiosInstance.post(URLS.role, body);
    setRoles(data.content);
  }, [])

  // const getCountry = useCallback(async () => {
  //   const { data } = await axiosInstance.get(URLS.countries);
  //   const filter = data.map((item: any) => { return ({ label: item.name, value: item.id }) });
  //   setCountry(filter);
  // }, [])
  // const getState = async (id: number) => {
  //   const { data } = await axiosInstance.get(URLS.states(id));
  //   const filter = data.map((item: any) => { return ({ label: item.name, value: item.id }) });
  //   setStates(filter);
  // }
  // const getCities = async (id: number) => {
  //   const { data } = await axiosInstance.get(URLS.cities(id));
  //   const filter = data.map((item: any) => { return ({ label: item.name, value: item.id }) });
  //   setCities(filter);
  // }

  useEffect(() => {
    (async () => {
      await getLanguage();
      getRoles();
    })();
  }, [getLanguage, getRoles]);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const update = async () => {
    try {
      setHasError(false);
      const { getFormData } = editProfileForm.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData, isFormValid } = getFormData();
      if (isFormValid) {
        setLoading(true);
        const body = removeErrorFieldsFromValues(formData);
        // const city = cities.find((el: any) => el.value === body.city);
        // const countries = country.find((el: any) => el.value === body.country);
        // const state = states.find((el: any) => el.value === body.state);
        const { status } = await axiosInstance.put(URLS.editProfileById(userData.id),
          { ...body, roles: fetchIdsFromSelectedDropdownValues(body.roles), language: body.language?.value });
        if (status === 200) {
          setLoading(false);
          setMessage({
            display: true,
            severity: "success",
            message: strings.userprofile_success_message,
          });
          await getUser();
        }
      } else {
        setHasError(true);
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setMessage({
          display: true,
          severity: "warning",
          message: error.response.data.message,
        });
      }
    }
  };

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };
  // const handleChange = (key: string, value: any, formdata: any) => {
  //   if (key === 'country') {
  //     getState(value);
  //     setUserData({ ...userData, state: '', country: value });
  //   }
  //   if (key === 'state') {
  //     getCities(value);
  //     setUserData({ ...userData, city: '', state: value });
  //   }
  // }

  return (
    <Container maxWidth={false}>
      {!!userData && (
        <>
          <Grid container spacing={2}>
            {message.display && (
              <Notification
                isOpen={message.display}
                message={message.message}
                severity={message.severity as AlertColor}
                closeNotification={closeNotification}
              />
            )}
            <Grid item xs={10}>
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}>User Profile</Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 2 }} />
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Form
              hasError={hasError}
              dropdownData={{
                roles: createDropdownData(roles, ['id', 'name']),
                language: createDropdownData(language, ['id', 'name']),
              }}
              ref={editProfileForm as RefObject<Form>}
              values={userData}
              model={EditProfileForm(language)}
            />
            <Grid container item>
              <PrimaryButton
                disabled={loading}
                onClick={update}
                variant={"contained"}
                fullWidth={false}
                sx={{ color: "#ffffff" }}
              >
                {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                Update Profile
              </PrimaryButton>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default EditProfile;
