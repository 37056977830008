import { FormModel } from "../../../components/Form";

export const FilterForm = (): FormModel[] => {
  return [
    {
      label: "Name",
      value: "",
      size: "medium",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "name",
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
  ];
};
