import React, { FC, useCallback, useEffect, useState } from "react";
import "./UserManagement.scss";
import { routes } from "../../../utils/constants/routes";
import ListPage from "../CommonPage";
import { URLS } from "../../../utils/constants/urls";
import { privileges } from "../../../utils/constants/privileges";
import axiosInstance from "../../../utils/axios";
import { images } from "../../../utils/constants/images";
import { AlertColor } from "@mui/material";
import Notification from "../../../components/Notification";
import PageLoader from "../../../components/PageLoader";
import strings from "../../../common/Translation/Translate";
import { UserFilter } from "./UserFilter";

const UserManagement: FC = () => {
    const [pageResponse, setPageResponse] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<{
        display: boolean;
        severity: AlertColor | null;
        message: string;
    }>({
        display: false,
        severity: null,
        message: "",
    });
    const handleCustomImageMethod = async (id: number) => {
        try {
            setMessage({
                display: false,
                severity: null,
                message: "",
            });
            setLoading(true);
            const { status } = await axiosInstance.put(URLS.resetUser(id));
            if (status === 200) {
                setLoading(false);
                setMessage({
                    display: true,
                    severity: "success",
                    message: strings.changePassword_success_message,
                });
            }
        } catch (error: any) {
            setLoading(false);
            setMessage({
                display: true,
                severity: "warning",
                message: error.response.data.message || strings.something_went_wrong
            });
        }
    }
    const toggleUser = async (id: number) => {
        try {
            setMessage({
                display: false,
                severity: null,
                message: "",
            });
            setLoading(true);
            const { status } = await axiosInstance.put(URLS.toggleUser(id));
            if (status === 200 || status === 201) {
                setLoading(false);
                setMessage({
                    display: true,
                    severity: "success",
                    message: strings.status_change_success_message,
                });
                getList();
            }
        } catch (error: any) {
            setMessage({
                display: true,
                severity: "warning",
                message: error.response.data.message || strings.something_went_wrong
            });
            setLoading(false);
        }
    }
    const pageConfig = {
        title: 'User Management',
        AddButtonText: 'Add User',
        AddButtonUrl: routes.addUser,
        editButtonUrl: routes.users,
        listApi: URLS.users,
        deleteApi: URLS.deleteUserById,
        editPrivilege: privileges.edit_user,
        deletePrivilege: privileges.delete_user,
        addPrivilege: privileges.add_user,
        customImage: {
            imageUrl: images.resetPasswordIcon,
            handleCustomImageMethod,
            privilege: privileges.reset_password_user
        },
        resetPasswordPrivilege: privileges.reset_password_user,
        blockUserPrivilege: privileges.block_user,
        toggleUser,
        filterForm: UserFilter(strings),
        tableColumn: [
            { field: 'firstName', headerName: 'First name', flex: 1 },
            { field: 'lastName', headerName: 'Last name', flex: 1 },
            { field: 'email', headerName: 'Email', flex: 1 },
            { field: 'phone', headerName: 'Phone Number', flex: 1 },
            { field: 'assignedGroupName', headerName: 'Assigned Group', flex: 1 },
            
        ]
    }
    const getList = useCallback(async (filterData?: any) => {
        try {
            setLoading(true);
            const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
            const { data } = await axiosInstance.post(pageConfig.listApi, body);
            setPageResponse(data);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
        }
    }, [pageConfig.listApi]);

    useEffect(() => {
        (async () => {
            await getList();
        })();
    }, [getList]);
    const closeNotification = (value: boolean) => {
        setMessage({ ...message, display: value });
    };
    return (<>
        {loading && <PageLoader />}
        {message.display && (
            <Notification
                isOpen={message.display}
                message={message.message}
                severity={message.severity as AlertColor}
                closeNotification={closeNotification}
            />
        )}<ListPage pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} /></>)
};
export default UserManagement;
