export const privileges = {
  view_role: "VIEW_ROLE",
  delete_role: "DELETE_ROLE",
  add_user: "ADD_USER",
  edit_user: "EDIT_USER",
  delete_user: "DELETE_USER",
  block_user: "BLOCK_USER",
  reset_password_user: "RESET_PASSWORD_USER",
  view_user: "VIEW_USER",
  add_role: "ADD_ROLE",
  edit_role: "EDIT_ROLE",
  edit_privileges: "EDIT_PRIVILEGES",
  view_agent: "VIEW_AGENT",
  add_agent: "ADD_AGENT",
  edit_agent: "EDIT_AGENT",
  delete_agent: "DELETE_AGENT",
  view_ticket_source: "VIEW_TICKET_SOURCE",
  add_ticket_source: "ADD_TICKET_SOURCE",
  edit_ticket_source: "EDIT_TICKET_SOURCE",
  delete_ticket_source: "DELETE_TICKET_SOURCE",
  view_ticket: "VIEW_TICKET",
  add_ticket: "ADD_TICKET",
  edit_ticket: "EDIT_TICKET",
  delete_ticket: "DELETE_TICKET",
  view_ticket_status: "VIEW_TICKET_STATUS",
  add_ticket_status: "ADD_TICKET_STATUS",
  edit_ticket_status: "EDIT_TICKET_STATUS",
  delete_ticket_status: "DELETE_TICKET_STATUS",
  view_assign_group: "VIEW_ASSIGN_GROUP",
  add_assign_group: "ADD_ASSIGN_GROUP",
  edit_assign_group: "EDIT_ASSIGN_GROUP",
  delete_assign_group: "DELETE_ASSIGN_GROUP",
  view_child_attribute: "VIEW_CHILD_ATTRIBUTE",
  add_child_attribute: "ADD_CHILD_ATTRIBUTE",
  edit_child_attribute: "EDIT_CHILD_ATTRIBUTE",
  delete_child_attribute: "DELETE_CHILD_ATTRIBUTE",
  view_issue_type: "VIEW_ISSUE_TYPE",
  add_issue_type: "ADD_ISSUE_TYPE",
  edit_issue_type: "EDIT_ISSUE_TYPE",
  delete_issue_type: "DELETE_ISSUE_TYPE",
  view_key_attribute: "VIEW_KEY_ATTRIBUTE",
  add_key_attribute: "ADD_KEY_ATTRIBUTE",
  edit_key_attribute: "EDIT_KEY_ATTRIBUTE",
  delete_key_attribute: "DELETE_KEY_ATTRIBUTE",
  view_ticket_category: "VIEW_TICKET_CATEGORY",
  add_ticket_category: "ADD_TICKET_CATEGORY",
  edit_ticket_category: "EDIT_TICKET_CATEGORY",
  delete_ticket_category: "DELETE_TICKET_CATEGORY",
  view_ticket_sub_category: "VIEW_TICKET_SUB_CATEGORY",
  add_ticket_sub_category: "ADD_TICKET_SUB_CATEGORY",
  edit_ticket_sub_category: "EDIT_TICKET_SUB_CATEGORY",
  delete_ticket_sub_category: "DELETE_TICKET_SUB_CATEGORY",
  view_ticket_sub_source: "VIEW_TICKET_SUB_SOURCE",
  add_ticket_sub_source: "ADD_TICKET_SUB_SOURCE",
  edit_ticket_sub_source: "EDIT_TICKET_SUB_SOURCE",
  delete_ticket_sub_source: "DELETE_TICKET_SUB_SOURCE",
  view_ticket_type: "VIEW_TICKET_TYPE",
  add_ticket_type: "ADD_TICKET_TYPE",
  edit_ticket_type: "EDIT_TICKET_TYPE",
  delete_ticket_type: "DELETE_TICKET_TYPE",
  show_admin_menu: "SHOW_ADMIN_MENU"
};
