import { Validators } from "../../../../../../utils/validators";
import { FormModel } from "../../../../../../components/Form";
export const AddNewUserGroupsForm = (strings: any): FormModel[] => {
  return [
    {
      label: `${strings.assign_create_group_label} *`,
      value: "",
      size: "medium",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "assignGroup",
      validators: [
        { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 4 },
      required: true,
    },
  ];
};
