import React, { FC, useState, useEffect, useCallback, } from "react";
import { useParams } from "react-router-dom";
import { AddUserForm } from "./AddUserForm";
import { routes } from "../../../utils/constants/routes";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";
import { getRoles } from "./userService";
import AddEdit from "../CommonPage/AddEdit";
import { createDropdownData, fetchIdsFromSelectedDropdownValues } from "../../../utils/validators/HelperFunctions";

const AddEditUser: FC = () => {
    const { id } = useParams() as any;
    const [roleList, setRoleList] = useState<any>([]);
    const [pageResponse, setPageResponse] = useState<any>([]);
    const [language, setLanguage] = useState<any>([]);
    const [userGroup, setUserGroup] = useState<any>([]);

    // const [country, setCountry] = useState<any>([]);
    // const [states, setStates] = useState<any>([]);
    // const [cities, setCities] = useState<any>([]);

    const getUser = useCallback(async () => {
        try {
            const { data } = await axiosInstance.get(URLS.getUserById(id));
            const setNewData = {
                ...data, groups: data.assignGroups
                // ...data, roles: data.role.value, language: data?.language?.value, city: data?.city?.value, state: data?.state?.value, country: data?.country?.value
            }
            // if (data?.country?.value) {
            //     await getState(data?.country?.value);
            // }
            // if (data?.state?.value) {
            //     await getCities(data?.state?.value);
            // }
            setPageResponse(setNewData);
        } catch (error: any) {
        }
    },
        [id]
    );

    useEffect(() => {
        (async () => {
            if (id) {
                await getUser();
            }
        })();
    }, [getUser, id]);

    useEffect(() => {
        (async () => {
            try {
                const response = await getRoles();
                if (response) {
                    setRoleList(response);
                }
            } catch (e) {

            }
        })();
    }, []);
    // const getCountry = useCallback(async () => {
    //     const { data } = await axiosInstance.get(URLS.countries);
    //     const filter = data.map((item: any) => { return ({ label: item.name, value: item.id }) });
    //     setCountry(filter);
    // }, [])
    // const getState = async (id: number) => {
    //     const { data } = await axiosInstance.get(URLS.states(id));
    //     const filter = data.map((item: any) => { return ({ label: item.name, value: item.id }) });
    //     setStates(filter);
    // }
    // const getCities = async (id: number) => {
    //     const { data } = await axiosInstance.get(URLS.cities(id));
    //     const filter = data.map((item: any) => { return ({ label: item.name, value: item.id }) });
    //     setCities(filter);
    // }
    const getLanguage = useCallback(async () => {
        const { data } = await axiosInstance.get(URLS.language);
        const filter = data.map((item: any) => { return ({ name: item.text, id: item.text }) });
        setLanguage(filter);
    }, [])

    const getuserGroups = useCallback(async () => {
        const body = {
            page: 1,
            size: 100,
            filter: [],
            sort: []
        }
        const seen = new Set();
        const { data } = await axiosInstance.post(URLS.getUserGroups, body);
        const filteredArr = data.content?.filter((el:any) => {
            const duplicate = seen.has(el.assignGroup);
            seen.add(el.assignGroup);
            return !duplicate;
          });
        const filter = filteredArr?.map((item: any) => { return ({ name: item.assignGroup, id: item.id }) });
        setUserGroup(filter);
    }, [])
    useEffect(() => {
        getuserGroups();
        // getCountry();
    }, [getuserGroups])
    useEffect(() => {
        getLanguage();
        // getCountry();
    }, [getLanguage])
    const values = {
        roles: createDropdownData(roleList, ['id', 'name']),
        groups: createDropdownData(userGroup, ['id', 'name']),
        language: createDropdownData(language, ['id', 'name']),
    }
    const pageConfig =
    {
        title: id ? 'Edit User' : 'Add user',
        successMessage: id ? "User has been updated Successfully" : "User has been added Successfully",
        addEditApiUrl: id ? URLS.editUserById(id) : URLS.addUser,
        initialApi: URLS.getUserById(id),
        formModel: AddUserForm(),
        navigateToPage: routes.users,
        isCustomResponse: false,
    }
    // const handlePropsChange = (key: string, value: any, formData: any) => {
    //     if (key === 'country') {
    //         getState(value);
    //         setPageResponse({ ...formData, id: pageResponse.id, state: '', country: value });
    //     }
    //     if (key === 'state') {
    //         getCities(value);
    //         setPageResponse({ ...formData, id: pageResponse.id, city: '', state: value });
    //     }

    // }
    const handleCustomResponse = (custom: any) => {
        // const roleId = roleList.find((el: any) => el.value === custom.roles);
        // const countries = country.find((el: any) => el.value === custom.country);
        // const state = states.find((el: any) => el.value === custom.state);
        if (custom) {
            // return { ...custom, city: city.label, country: countries.label, state: state.label, id: pageResponse.id, role: custom.roles }
            return { ...custom, id: pageResponse.id,language:custom?.language?.value||'', roles: fetchIdsFromSelectedDropdownValues(custom.roles), groups: fetchIdsFromSelectedDropdownValues(custom.groups) }
        }
    }
    return (<AddEdit dropdownValue={values} pageConfig={pageConfig} pageResponse={pageResponse} handleCustomResponse={handleCustomResponse} />);
};
export default AddEditUser;