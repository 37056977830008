import { useState, FC, useEffect } from "react";
import "./style.scss";
import { validateInput } from "../../utils/validators/Validators";
import { TextFieldProps, Grid, FormHelperText, Typography, FormControl, IconButton } from "@mui/material";
import { FormValidators } from "../Form";
import FileUpload from "react-material-file-upload";
import { CloudDownloadOutlined } from "@mui/icons-material";

interface OwnProps {
    inputValue?: [] | undefined;
    field?: string;
    typeValue?: any;
    disabled?: boolean,
    textChange?: (
        value: string,
        field: string,
        error?: { error: boolean; message: string },
        deleted?: any
    ) => void;
    validators?: FormValidators[];
    hasError?: boolean;
    fieldError?: boolean | string;
    multiline?: boolean;
    deleteImages?: [];
    sx?: any,
    handledeleteImage?: (id: number) => void
}

const Upload: FC<OwnProps & TextFieldProps> = ({
    hasError,
    validators,
    textChange,
    field,
    inputValue,
    disabled=false,
    fieldError,
    label,
    sx,
    multiline = false,
}) => {
    const [error, setError] = useState<{
        error: boolean;
        message: string;
    } | null>(null);
    const [files, setFiles] = useState<File[]>(inputValue as any);
    const handleChange = (value: any, deleted?: any) => {
        value.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
            }),
        );
        const inputError = validateInput(validators as FormValidators[], value);
        setError(inputError);
        setFiles(value)
        if (textChange) {
            textChange(value, field as string, inputError, deleted as any);
        }
    };
    useEffect(() => {
        setFiles(inputValue as any)
    }, [inputValue])
    return (
        <Grid container spacing={2} className='upload'>
            <Grid item xs={12} sx={{ mt: 0 }}>
                <Typography variant="subtitle1">{label}</Typography>
                <FileUpload
                    disabled={disabled}
                    multiple={multiline}
                    sx={{ border: "2px dashed",p:0, minHeight: "10px", ...sx }}
                    value={files as any}
                    onChange={handleChange as any}
                    buttonProps={{ sx: { color: "#ffffff" } }}
                    buttonText={"Choose Files"}
                />
                <FormControl
                    error={!!((error && error.error) || (!error && hasError && fieldError))}>
                    <FormHelperText>
                        {error && error.error
                            ? error.message
                            : !error && hasError && fieldError
                                ? "This field is required"
                                : ""}
                    </FormHelperText>
                </FormControl>
            </Grid>
            {inputValue && inputValue?.length > 0 && (
                <Grid container sx={{ mt: 0, mb: 0, pt:0 }}>
                    {inputValue?.map((fileData: any, index: number) => (
                        <Grid
                            item
                            key={index}
                            sm={4}
                            sx={{
                                mx:2,
                                px: 2,
                                textAlign:'center',
                                position: 'relative',
                                minHeight: 50,
                                alignItems: 'center',
                                border: "1px solid #1ca7b1",
                                justifyContent: 'center',
                                borderRadius: "10px",
                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)",
                                // ml: 0,
                                // width: "calc(100% - 15px)",
                            }}
                        >
                            <IconButton
                                sx={{ p: 0 }}
                                color={"primary"}
                            // sx={{ position: 'absolute', top: 0, right: 10 }}
                            >
                                <a  rel="noreferrer" href={fileData.preview} target="_blank" className="download" download>
                                    <CloudDownloadOutlined
                                    />
                                </a>
                            </IconButton>
                            {/* <DescriptionIcon sx={{ fontSize: "50px" }} /> */}
                            <Typography title={fileData.preview} variant="caption">{fileData.name}</Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};
export default Upload;
