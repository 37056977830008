import { Validators } from "../../../utils/validators";
import { FormModel } from "../../../components/Form";
import strings from "../../../common/Translation/Translate";

export const EditProfileForm = (language: []): FormModel[] => {
  return [
    {
      label: `${strings.updateProfile_text_firstName} *`,
      value: "",
      size: "medium",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "firstName",
      validators: [
        { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 4 },
      required: true,
    },
    {
      label: `${strings.updateProfile_text_lastName} *`,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "lastName",
      validators: [
        { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 4 },
      required: true,
    },
    {
      label: `${strings.updateProfile_text_phoneNumber} *`,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "phone",
      validators: [
        { check: Validators.required, message: strings.requiredText },
        { check: Validators.mobile, message: strings.required_valid_mobile },
      ],
      responsive: { xs: 4 },
      required: true,
      disabled: true,
    },
    {
      label: `${strings.updateProfile_text_email} *`,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "email",
      validators: [
        { check: Validators.required, message: strings.requiredText },
        { check: Validators.email, message: strings.invalid_email_error },
      ],
      responsive: { xs: 4 },
      required: true,
      disabled: true,
    },
    {
      label: `${strings.updateProfile_text_pinCode} *`,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "pinCode",
      validators: [
        { check: Validators.required, message: strings.requiredText },
        { check: Validators.pinCode, message: strings.valid_pinCode_error },
      ],
      responsive: { xs: 4 },
      required: true,
    },
    {
      label: `${strings.updateProfile_text_address} *`,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "address",
      validators: [
        { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 4 },
      required: true,
    },
    // {
    //   label: `${strings.updateProfile_text_role} *`,
    //   value: "",
    //   size: "medium",
    //   type: "text",
    //   typeValue: "text",
    //   variant: "outlined",
    //   placeholder: "",
    //   field: "role",
    //   validators: [],
    //   responsive: { xs: 4 },
    //   required: true,
    //   disabled: true,
    // },
    {
      label: `${strings.updateProfile_text_role} *`,
      value: "",
      size: "medium",
      type: "select",
      placeholder: `${strings.updateProfile_text_role}`,
      field: "roles",
      validators: [
          { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 12, md: 6 },
      required: true,
      isMulti: true,
      disabled:true
  },
    {
      label: `${strings.updateProfile_text_language} *`,
      value: "",
      size: "medium",
      type: "select",
      placeholder: `${strings.updateProfile_text_language}`,
      field: "language",
      validators: [
          { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 12, md: 6 },
      required: true,
      isMulti: false
  },
    // {
    //   label: `${strings.updateProfile_text_companyName}`,
    //   value: "",
    //   size: "medium",
    //   type: "text",
    //   typeValue: "text",
    //   variant: "outlined",
    //   placeholder: "",
    //   field: "companyName",
    //   validators: isCompany ? [
    //     { check: Validators.required, message: strings.requiredText },
    //   ] : [],
    //   responsive: { xs: 4 },
    //   required: isCompany,
    //   disabled: !isCompany,
    // },
    // {
    //   label: `${strings.updateProfile_text_gst}`,
    //   value: "",
    //   size: "medium",
    //   type: "text",
    //   typeValue: "text",
    //   variant: "outlined",
    //   placeholder: "",
    //   field: "gst",
    //   validators: isCompany ? [
    //     { check: Validators.required, message: strings.requiredText },
    //   ] : [],
    //   responsive: { xs: 4 },
    //   required: isCompany,
    //   disabled: !isCompany,
    // },
    // {
    //   label: `${strings.updateProfile_text_designation}`,
    //   value: "",
    //   size: "medium",
    //   type: "text",
    //   typeValue: "text",
    //   variant: "outlined",
    //   placeholder: "",
    //   field: "designation",
    //   validators: isCompany ? [
    //     { check: Validators.required, message: strings.requiredText },
    //   ] : [],
    //   responsive: { xs: 4 },
    //   required: isCompany,
    //   disabled: !isCompany,
    // },
    // {
    //   label: `${strings.updateProfile_text_country} *`,
    //   value: "",
    //   size: "medium",
    //   type: "drop-down",
    //   placeholder: "",
    //   field: "country",
    //   validators: [
    //     { check: Validators.required, message: "This field is mandatory" },
    //   ],
    //   responsive: { xs: 4 },
    //   required: true,
    //   options: country,
    // },
    // {
    //   label: `${strings.updateProfile_text_state} *`,
    //   value: "",
    //   size: "medium",
    //   type: "drop-down",
    //   placeholder: "",
    //   field: "state",
    //   validators: [
    //     { check: Validators.required, message: "This field is mandatory" },
    //   ],
    //   responsive: { xs: 4 },
    //   required: true,
    //   options: states,
    // },
    // {
    //   label: `${strings.updateProfile_text_city} *`,
    //   value: "",
    //   size: "medium",
    //   type: "drop-down",
    //   placeholder: "",
    //   field: "city",
    //   validators: [
    //     { check: Validators.required, message: "This field is mandatory" },
    //   ],
    //   responsive: { xs: 4 },
    //   required: true,
    //   options: cities,
    // },
  ];
};
