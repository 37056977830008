import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Grid,
  Typography,
  Divider,
  Stack,
  Avatar,
  Container,
  useTheme,
  Paper,
  IconButton,
} from "@mui/material";
import "./UserProfile.scss";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/constants/routes";
import { Close } from "@mui/icons-material";

const UserProfile: FC = () => {
  const theme = useTheme();
  const [userData, setUserData] = useState<any>();
  const Navigate = useNavigate();
  const getUser = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.userProfile);
      setUserData(data);
    } catch (error: any) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);


  return (
    <Container maxWidth={false}>
      {!!userData && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: 24, fontWeight: 500 }}>User Profile</Typography>
            </Grid>
            <Grid item textAlign='right' xs={2}>
                    <IconButton onClick={() => Navigate(-1)} aria-label="delete">
                        <Close />
                    </IconButton>
                </Grid>
          </Grid>
          <Divider sx={{ mt: 2 }} />
          <Paper sx={{ p: 1 }}>
            <Stack direction="row" spacing={5} sx={{ m: 2 }}>
              <Avatar
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  width: 80,
                  height: 80,
                }}
              >
                <Typography variant="h3">
                  {userData.firstName?.slice(0, 1)}
                </Typography>
              </Avatar>
              <Grid className="info">
                <Typography className="profile-name">
                  {userData?.firstName} {userData?.lastName}
                </Typography>
                <Typography className="profile-info">{userData?.email}</Typography>
                <Typography className="profile-info">{userData?.phone}</Typography>
                <Typography className="profile-info">{userData?.role?.label}</Typography>
                <Typography className="profile-info">{userData?.address}, {userData.pinCode}</Typography>
                {userData.roles?.length > 0 && <Typography variant="h6">{userData.roles[0].label}</Typography>}
                <PrimaryButton
                  onClick={() => Navigate(routes.editProfile + "/" + userData.id)}
                  variant={"outlined"}
                  fullWidth={false}
                  sx={{ mt: 4 }}
                >
                  Edit Profile
                </PrimaryButton>
              </Grid>
            </Stack>
          </Paper>

        </>
      )}
    </Container>
  );
};

export default UserProfile;
