import {Validators} from "../../../utils/validators";
import {FormModel} from "../../../components/Form";

export const ForgotForm = (): FormModel[] => {
    return [
        {
            label: 'Email ID *',
            value: '',
            size: 'medium',
            autoFocus: true,
            type: 'text',
            typeValue: 'text',
            variant: 'outlined',
            placeholder: '',
            field: 'email',
            validators: [
                {check: Validators.required, message: 'This field is mandatory'},
                {check: Validators.email, message: 'Invalid Email ID'},
            ],
            responsive: {xs: 12},
            required: true,
        },
    ];
}