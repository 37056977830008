import { FC, JSXElementConstructor, ReactElement, useCallback, useEffect, useState } from "react";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";
import strings from "../../../common/Translation/Translate";
import { Container, Typography, Grid, Box, TextFieldProps, Button } from "@mui/material";
import Charts from "../../../components/Chart/Chart";
import PageLoader from "../../../components/PageLoader";
import DateInput from "../../../components/DateInput/DateInput";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import dayjs from "dayjs";
import axios from "axios";
import { fetchFromStorage } from "../../../utils/storage";
import { identifiers } from "../../../utils/constants/identifiers";
import { downloadExportFile } from "../../../utils/validators/HelperFunctions";

dayjs.extend(isSameOrAfter)

const options = {
  chartArea: {
    left: "3%",
    top: "3%",
    height: "94%",
    width: "94%"
  }
}
const Dashboard: FC = () => {
  const [pageResponse, setPageResponse] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<any>({ startDate: dayjs().date(1).format("YYYY-MM-DD"), endDate: dayjs().endOf('month').format("YYYY-MM-DD") });
  // const theme = useTheme();
  const getList = useCallback(async () => {
    try {
      if (dayjs(dates.endDate).isSameOrAfter(dayjs(dates.startDate))) {
        setLoading(true)
        const { data } = await axiosInstance.get(`${URLS.dashboard}?startDate=${dates.startDate}&endDate=${dates.endDate}`);
        setLoading(false)
        setPageResponse(data);
      } else {
        alert('start date should be less than end date')
      }

    } catch (error: any) {
      setLoading(false)
    }
  }, [dates.startDate, dates.endDate]);

  useEffect(() => {
    (async () => {
      await getList();
    })();
  }, [getList]);
  // const borderStyles = {
  //   border: `1px solid ${theme.palette.grey[300]}`,
  //   borderRadius: '6px',
  // };
  const boxStyle = { background: '#fff', height: '95%' }
  const handleDate = (value: string, field: string, error: { error: boolean; message: string; }) => {
    if (field === 'startDate') {
      setDates({ ...dates, startDate: value });
    } else {
      setDates({ ...dates, endDate: value });
    }
  }
  const handleCLick = () => {
    if (dayjs(dates.endDate).isSameOrAfter(dayjs(dates.startDate))) {
    const token = fetchFromStorage(identifiers.access_token);
    try {
      setLoading(true)
      const exportData = { startDate: dates.startDate, endDate: dates.endDate };
      // exportData.size = totalPage;
      axios({
        url: `${process.env.REACT_APP_BASE_API_URL + URLS.exportCsv}?startDate=${dates.startDate}&endDate=${dates.endDate }`,
        method: 'GET',
        data: exportData,
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
      })
        .then(response => {
          setLoading(false)
          downloadExportFile(response, 'csv', `reports`);
        })
        .catch(err => {
          setLoading(false)
        });
    } catch (err) {
      setLoading(false)
    }}else {
      alert('start date should be less than end date')
    }
  }
  return (
    <Container maxWidth={false}>
      {loading && <PageLoader />}
      <Grid container spacing={1}>
        <Grid item xs={10} mb={2}>
          <Typography sx={{ fontSize: 24, fontWeight: 700 }}>{strings.dashboard_text_title}</Typography>
        </Grid>
      </Grid>
      <Grid alignItems='center' direction='row' container spacing={2}>
        <Grid item xs={4} mb={2}>
          <DateInput field="startDate" value={dates.startDate} textChange={handleDate as any} onChange={function (value: any, keyboardInputValue?: string | undefined): void {
            throw new Error("Function not implemented.");
          }} renderInput={function (props: TextFieldProps): ReactElement<any, string | JSXElementConstructor<any>> {
            throw new Error("Function not implemented.");
          }} />
        </Grid>
        <Grid item xs={4} mb={2}>
          <DateInput field="endDate" value={dates.endDate} textChange={handleDate as any} onChange={function (value: any, keyboardInputValue?: string | undefined): void {
            throw new Error("Function not implemented.");
          }} renderInput={function (props: TextFieldProps): ReactElement<any, string | JSXElementConstructor<any>> {
            throw new Error("Function not implemented.");
          }} />
        </Grid>
        <Button onClick={handleCLick} sx={{ height: '100%', minHeight: '56px', marginTop: '-10px', marginLeft: '20px' }} variant="outlined" startIcon={<CloudDownloadIcon />}>
          Export
        </Button>
      </Grid>
      {/* <Grid
        md={3}
        component={Paper}
        sx={{
          ...borderStyles,
          border: `1px solid ${theme.palette.grey[200]}`,
          height: 'fit-content',
        }}
        p={2}
        mb={3}
        elevation={0}
      >
        <Typography fontSize="13px" fontWeight={500}>
          INDEXED RECORDS
        </Typography>
        <Stack spacing={3} direction="row">
          <Stack>
            <Typography fontSize="18.52px" fontWeight={500}>
              1000
            </Typography>
            <Typography fontSize="14.6px" fontWeight={400}>
              Total Records
            </Typography>
          </Stack>
          <Stack fontSize="14.6px" fontWeight={400}>
            <Typography fontSize="18.52px" fontWeight={500}>
              1212
            </Typography>
            <Typography fontSize="14.6px" fontWeight={400}>
              In Queue
            </Typography>
          </Stack>
        </Stack>
      </Grid> */}
      <Grid container xs={12}>
        <Grid item xs={12} md={6}>
          <Box minHeight='250px' m={1} p={2} sx={boxStyle}>
            <Typography variant="h6">{strings.dashboard_text_categoryWiseTickets}</Typography>
            {pageResponse.categoryWiseTickets?.length > 0 ?
              <Charts type="PieChart" data={pageResponse.categoryWiseTickets} options={options} id={'categoryWiseTickets'} height='100%' />
              : <Typography mt={2} variant="subtitle2">{strings.no_data_available}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box minHeight='250px' m={1} p={2} sx={boxStyle}>
            <Typography variant="h6">{strings.dashboard_text_groupWiseTickets}</Typography>
            {pageResponse.groupWiseTickets?.length > 0 ?
              <Charts type="PieChart" data={pageResponse.groupWiseTickets} options={options} id={'groupWiseTickets'} height='100%' />
              : <Typography mt={2} variant="subtitle2">{strings.no_data_available}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box minHeight='250px' m={1} p={2} sx={boxStyle}>
            <Typography variant="h6">{strings.dashboard_text_issueTypeWiseTickets}</Typography>
            {pageResponse.groupWiseTickets?.length > 0 ?
              <Charts type="PieChart" data={pageResponse.issueTypeWiseTickets} options={options} id={'issueTypeWiseTickets'} height='100%' />
              : <Typography mt={2} variant="subtitle2">{strings.no_data_available}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box minHeight='250px' m={1} p={2} sx={boxStyle}>
            <Typography variant="h6">{strings.dashboard_text_sourceWiseTickets}</Typography>
            {pageResponse.groupWiseTickets?.length > 0 ?
              <Charts type="PieChart" data={pageResponse.sourceWiseTickets} options={options} id={'sourceWiseTickets'} height='100%' />
              : <Typography mt={2} variant="subtitle2">{strings.no_data_available}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box minHeight='250px' m={1} p={2} sx={boxStyle}>
            <Typography variant="h6">{strings.dashboard_text_ticketTypeWiseTickets}</Typography>
            {pageResponse.groupWiseTickets?.length > 0 ?
              <Charts type="PieChart" data={pageResponse.ticketTypeWiseTickets} options={options} id={'ticketTypeWiseTickets'} height='100%' />
              : <Typography mt={2} variant="subtitle2">{strings.no_data_available}</Typography>}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box minHeight='250px' m={1} p={2} sx={boxStyle}>
            <Typography variant="h6">{strings.dashboard_text_resolutionTatWiseTickets}</Typography>
            {pageResponse.resolutionTatWiseTickets?.length > 0 ?
              <Charts type="PieChart" data={pageResponse.resolutionTatWiseTickets} options={options} id={'resolutionTatWiseTickets'} height='100%' />
              : <Typography mt={2} variant="subtitle2">{strings.no_data_available}</Typography>}
          </Box>
        </Grid>
      </Grid>
    </Container>)
};
export default Dashboard;
