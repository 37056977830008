import { Validators } from "../../../utils/validators";
import { FormModel } from "../../../components/Form";

export const ResetForm = (firstTimeLogin: boolean): FormModel[] => {
    return [
        {
            label: firstTimeLogin ? 'Old Password *' : 'New Password *',
            value: '',
            size: 'medium',
            type: 'text',
            typeValue: 'password',
            variant: 'outlined',
            placeholder: '',
            field: firstTimeLogin ? 'oldPassword' : 'password',
            validators: [
                { check: Validators.required, message: 'This field is mandatory' },
            ],
            responsive: { xs: 12 },
            required: true,
        },
        {
            label: firstTimeLogin ? 'New Password *' : 'Confirm Password *',
            value: '',
            size: 'medium',
            type: 'text',
            typeValue: 'password',
            variant: 'outlined',
            placeholder: '',
            field: firstTimeLogin ? 'newPassword' : 'confirmPassword',
            validators: [
                { check: Validators.required, message: 'This field is mandatory' },
                { check: Validators.password, message: 'Password length should be between 8 and 15 characters and must contain at least one lowercase letter, one uppercase letter, one numeric digit and one special character' }
            ],
            responsive: { xs: 12 },
            required: true,
        },
    ];
}
