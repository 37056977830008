import React, { FC, useCallback, useEffect, useState } from "react";
import { routes } from "../../../../../utils/constants/routes";
import ListPage from "../../../CommonPage";
import { URLS } from "../../../../../utils/constants/urls";
import { privileges } from "../../../../../utils/constants/privileges";
import axiosInstance from "../../../../../utils/axios";
import strings from "../../../../../common/Translation/Translate";

const TicketSubCategoriesList: FC = () => {
  const [pageResponse, setPageResponse] = useState<any[]>([]);
  const pageConfig = {
    title: strings.ticket_sub_categories_title,
    AddButtonText: strings.ticket_sub_categories_button_add,
    AddButtonUrl: routes.addTicketSubCategories,
    editButtonUrl: routes.editTicketSubCategories,
    listApi: URLS.ticketSubCategories,
    deleteApi: URLS.get_edit_delete_ticket_sub_categories,
    editPrivilege: privileges.edit_ticket_sub_category,
    deletePrivilege: privileges.delete_ticket_sub_category,
    addPrivilege: false,
    // filterForm:FilterForm(),
    tableColumn: [
      {
        field: "ticketSubCategory",
        headerName: strings.ticket_sub_categories_table_column_Ticket_name,
        flex: 6,
      },
    ],
  };

  const getList = useCallback(
    async (filterData?: any) => {
      try {
        const body = filterData
          ? filterData
          : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
        const { data } = await axiosInstance.post(pageConfig.listApi, body);
        setPageResponse(data);
      } catch (error: any) {}
    },
    [pageConfig.listApi]
  );

  useEffect(() => {
    (async () => {
      await getList(null);
    })();
  }, [getList]);

  return (
    <ListPage
      pageConfig={pageConfig}
      pageResponse={pageResponse}
      getList={getList}
    />
  );
};
export default TicketSubCategoriesList;
