import React, { FC, useCallback, useEffect, useState } from "react";
import { routes } from "../../../utils/constants/routes";
import ListPage from "../CommonPage";
import { URLS } from "../../../utils/constants/urls";
import { privileges } from "../../../utils/constants/privileges";
import axiosInstance from "../../../utils/axios";
import strings from "../../../common/Translation/Translate";


const UserManagement: FC = () => {
  const [pageResponse, setPageResponse] = useState<any[]>([]);
  const pageConfig = {
    title: strings.agent_title_list,
    AddButtonText: strings.agent_button_add,
    AddButtonUrl: routes.addAgent,
    editButtonUrl: routes.editAgent,
    listApi: URLS.agents,
    deleteApi: URLS.deleteAgent,
    editPrivilege: privileges.edit_agent,
    deletePrivilege: privileges.delete_agent,
    addPrivilege: privileges.add_agent,
    tableColumn: [
      { field: 'agent', headerName: strings.agent_column_name, flex: 1 },
    ]
  }

  const getList = useCallback(async (filterData?: any) => {
    try {
      const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
      const { data } = await axiosInstance.post(pageConfig.listApi, body);
      setPageResponse(data);
    } catch (error: any) { }
  }, [pageConfig.listApi]);

  useEffect(() => {
    (async () => {
      await getList();
    })();
  }, [getList]);

  return <ListPage pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} />
};
export default UserManagement;
