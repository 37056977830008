import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../../utils/axios";
import AddEdit from "../../../../CommonPage/AddEdit";
import { AddEditTicketSourceForm } from "./AddEditTicketSourceForm";
import { URLS } from "../../../../../../utils/constants/urls";
import strings from "../../../../../../common/Translation/Translate";
import { routes } from "../../../../../../utils/constants/routes";

const AddEditTicketSource: FC = () => {
  const { id } = useParams() as any;
  const Navigate = useNavigate();
  const [pageResponse, setPageResponse] = useState<any>([]);

  const getInitialResponse = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(
        URLS.get_edit_delete_ticket_source(id)
      );
      setPageResponse(data);
    } catch (error: any) {}
  }, [id]);
  const handleSecondaryButton = (id: number) => {
    Navigate(`/ticketSubSource/add?id=${id}`)
  }

  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);

  const pageConfig = {
    title: id
      ? strings.ticket_source_title_edit
      : strings.ticket_source_title_add,
    successMessage: id
      ? strings.common_update_message
      : strings.common_save_message,
    addEditApiUrl: id
      ? URLS.get_edit_delete_ticket_source(id)
      : URLS.addTicketSource,
    initialApi: URLS.editRole(id),
    formModel: AddEditTicketSourceForm(strings),
    navigateToPage: routes.ticketSource,
    isCustomResponse: false,
    secondaryButtonText: strings.ticket_sub_source_title_add
  };

  return <AddEdit pageConfig={pageConfig} pageResponse={pageResponse} handleSecondaryButton={handleSecondaryButton} />;
};
export default AddEditTicketSource;
