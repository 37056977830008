import { Validators } from "../../../../../utils/validators";
import { FormModel } from "../../../../../components/Form";
import strings from "../../../../../common/Translation/Translate";
export const AddRoleForm = (): FormModel[] => {
    return [
        {
            label: `${strings.roles_text_name} *`,
            value: "",
            size: "medium",
            autoFocus: true,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "name",
            validators: [
                { check: Validators.required, message: "This field is mandatory" },
            ],
            responsive: { xs: 4 },
            required: true,
        },

    ];
};
