import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  RefObject,
  useMemo,
} from "react";
import { routes } from "../../../../utils/constants/routes";
import { URLS } from "../../../../utils/constants/urls";
import { privileges } from "../../../../utils/constants/privileges";
import axiosInstance from "../../../../utils/axios";
import strings from "../../../../common/Translation/Translate";
import { images } from "../../../../utils/constants/images";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { Close } from "@mui/icons-material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  Container,
  Grid,
  Typography,
  TablePagination,
  List,
  TableRow,
  ListItemButton,
  Box,
  TableContainer,
  TableBody,
  Table,
  useTheme,
  TableFooter,
  Chip,
  Button,
  Divider,
  Popover,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import dayjs from "dayjs";
import Form, { FormDataModel } from "../../../../components/Form";
import { removeErrorFieldsFromValues } from "../../../../utils/validators";
import { FilterForm } from "../FilterForm";
import PageLoader from "../../../../components/PageLoader";
import AddIcon from "@mui/icons-material/Add";
import DropDownInput from "../../../../components/DropDownInput";
import { sortBy } from "../../../../utils/constants/data";
// import MultiSelect from "../../../../components/MultiSelect/MultiSelect";
// import { sortData } from "../../../../utils/constants/data";
// import { createDropdownData } from "../../../../utils/validators/HelperFunctions";

const TicketList: FC = () => {
  let filterRef: RefObject<Form | null | undefined> = useRef();
  const navigate = useNavigate();
  const theme = useTheme();
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageResponse, setPageResponse] = useState<any>([]);
  const [sort, setSort] = useState<any>("default");
  const [selectedSort, setSelectedSort] = useState<any>("createdAt:desc");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState<any>([]);

  const pageConfig = {
    title: strings.ticket_title,
    listApi: URLS.get_edit_delete_ticket,
    deleteApi: URLS.deleteRole,
    editPrivilege: privileges.edit_role,
    deletePrivilege: privileges.delete_role,
    addPrivilege: privileges.view_ticket,
    customImage: {
      imageUrl: images.privilegeIcon,
      privilege: privileges.edit_privileges,
    },
  };
  const getList = useCallback(
    async (filter?: string[]) => {
      const body = {
        page: index + 1,
        size: pageSize,
        filter: filter || [],
        sort: selectedSort ? [selectedSort] : ["createdAt:desc"],
      };
      try {
        setLoading(true);
        const { data } = await axiosInstance.post(URLS.add_ticket, body);
        setTotalRecords(data.totalElements);
        setPageResponse(data.content);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.log(error);
      }
    },
    [index, pageSize, selectedSort]
  );

  useEffect(() => {
    (async () => {
      await getList();
    })();
  }, [getList]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setIndex(newPage);
  };
  const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const anchor_id = open ? "simple-popover" : undefined;
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  const applyFilter = useCallback(
    async (reset: boolean) => {
      const filters: any = [];
      if (reset) {
        setFilter(null);
      } else {
        const { getFormData } = filterRef.current as {
          getFormData: () => { formData: FormDataModel; isFormValid: boolean };
        };
        const { formData } = getFormData();
        const filter_data = removeErrorFieldsFromValues(formData);
        setFilter(filter_data);
        Object.keys(filter_data).forEach((key) => {
          if (!!filter_data[key]) {
            // filters?.filter((item: any) => item !== null);
            if (filters.length > 0) {
              filters.push("and");
            }
            filters.push(
              filter_data[key] ? `${key}:cic:${filter_data[key]}` : null
            );
          }
        });
      }
      await getList(filters);
      handleCloseFilter();
    },
    [getList]
  );
  const popover = useMemo(() => {
    return (
      <Box width={320}>
        <Grid container sx={{ m: 2, ml: 0, px: 2 }}>
          <Grid item xs={5}>
            <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
              {strings.filter_text}
            </Typography>
          </Grid>
          <Grid item xs={5} textAlign={"right"}>
            <Button
              variant={"outlined"}
              onClick={() => applyFilter(true)}
              sx={{ p: 1 }}
            >
              <Typography variant={"body2"}>Clear Filter</Typography>
            </Button>
          </Grid>
          <Grid item xs={2} textAlign={"right"}>
            <Button variant={"text"} onClick={handleCloseFilter}>
              <Close />
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2, width: "calc(100% + 16px)" }} />
        <Grid container>
          <Grid item xs={12} sx={{ p: 2 }}>
            <Form
              ref={filterRef as RefObject<Form>}
              model={FilterForm()}
              values={filter}
            />
            <Button
              variant="contained"
              sx={{ my: 2, color: "#ffffff" }}
              fullWidth
              onClick={() => applyFilter(false)}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }, [filter, applyFilter]);

  const handleDroddownChange = (value: any) => {
    switch (value) {
      case "ageing":
        setSelectedSort("promisedResolutionDate:asc");
        break;
      case "latest":
        setSelectedSort("editedAt:desc");
        break;
      case "default":
        setSelectedSort("createdAt:desc");
        break;
      default:
        break;
    }
    setSort(value);
  };
  return (
    <Container maxWidth={false}>
      <Grid container spacing={1}>
        {loading && <PageLoader />}
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: 24, fontWeight: 500 }}>
              {pageConfig.title}
            </Typography>
          </Grid>
          <Grid
            sx={{
              ".MuiButtonBase-root": { height: "40px", minHeight: "40px" },
            }}
            gap={2}
            display="flex"
            justifyContent="end"
            item
            md={8}
          >
            <DropDownInput
              hasError={false}
              validators={[]}
              onChange={handleDroddownChange}
              field={undefined}
              inputValue={sort}
              options={sortBy(strings)}
              fieldError={false}
              inputProps={undefined}
              disabled={false}
              className={""}
              id="sortBy"
              labelId="sortBy"
              label="SortBy"
              style={{ width: "200px" }}
              placeholder="Sort By"
              size="small"
            />
            <PrimaryButton
              sx={{ height: "41px" }}
              onClick={handleFilter}
              fullWidth={false}
            >
              <FilterAltOutlinedIcon />
              <Typography variant={"body1"} sx={{ pl: 1 }} fontWeight={"bold"}>
                {strings.filter_text}
              </Typography>
            </PrimaryButton>
            <PrimaryButton
              sx={{ height: "41px", minHeight: "41px" }}
              onClick={() => navigate(routes.newTicket)}
              fullWidth={false}
            >
              <AddIcon />
              <Typography variant={"body1"} sx={{ pl: 1 }} fontWeight={"bold"}>
                {strings.menu_text_tickets}
              </Typography>
            </PrimaryButton>
          </Grid>
        </Grid>
        <Popover
          sx={{ m: 4, [`& .MuiPopover-paper`]: { borderRadius: 5 } }}
          id={anchor_id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {popover}
        </Popover>
        {pageResponse && pageResponse?.length > 0 ? (
          <TableContainer>
            <Table
              sx={{
                minWidth: 700,
                borderSpacing: "0 1rem",
                borderCollapse: "separate",
              }}
            >
              <TableBody>
                {pageResponse &&
                  pageResponse?.length > 0 &&
                  pageResponse?.map((item: any, index: number) => (
                    <Box
                      key={index}
                      mb={2}
                      sx={{
                        width: "100%",
                        bgcolor: theme.palette.secondary.main,
                      }}
                    >
                      <List
                        sx={{ p: 0 }}
                        component="nav"
                        aria-label="main mailbox folders"
                      >
                        <ListItemButton
                          onClick={() =>
                            navigate(routes.editTicket + "/" + item.id)
                          }
                        >
                          <Grid container>
                            <Grid mb={1} sm={12} item mt={1}>
                              {item.escalation && (
                                <Chip
                                  sx={{
                                    mr: 2,
                                    borderRadius: 0,
                                    fontSize: "12px",
                                    fontWeight: 700,
                                  }}
                                  color="error"
                                  label={
                                    strings.ticket_form_text_is_escalation_ticket
                                  }
                                />
                              )}
                              {item.resolutionTATBreached && (
                                <Chip
                                  sx={{
                                    mr: 2,
                                    borderRadius: 0,
                                    fontSize: "12px",
                                    fontWeight: 700,
                                  }}
                                  color="warning"
                                  label={
                                    strings.ticket_form_text_is_tat_brached
                                  }
                                />
                              )}
                              {item.newTicket && (
                                <Chip
                                  sx={{
                                    borderRadius: 0,
                                    fontSize: "12px",
                                    fontWeight: 700,
                                  }}
                                  color="info"
                                  label={strings.ticket_form_text_new_ticket}
                                />
                              )}
                            </Grid>

                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.ticket_form_text_ticket_id} : {item.id}
                              </Typography>
                            </Grid>
                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.ticket_form_text_buyer_code} :{" "}
                                {item.buyerId || "NA"}
                              </Typography>
                            </Grid>
                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.ticket_form_text_ticket_category}:{" "}
                                {item.ticketCategory?.ticketCategory}
                              </Typography>
                            </Grid>
                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.ticket_form_text_issue_type} :{" "}
                                {item.issueType.issueType}
                              </Typography>
                            </Grid>
                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.ticket_form_text_created_at} :{" "}
                                {dayjs(item.createdAt).format("DD/MM/YYYY")}
                              </Typography>
                            </Grid>
                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.menu_text_assign_groups} :
                                {item?.assignGroup?.assignGroup}
                              </Typography>
                            </Grid>
                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.ticket_form_text_issue_type} :{" "}
                                {item.issueType.issueType}
                              </Typography>
                            </Grid>
                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.ticket_form_text_ticket_status} :{" "}
                                {item.ticketStatus?.ticketStatus || "-"}
                              </Typography>
                            </Grid>
                            <Grid mb={1} sm={6} item>
                              <Typography>
                                {strings.ticket_form_text_is_email} :{" "}
                                {item.email || "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      </List>
                    </Box>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    colSpan={5}
                    count={totalRecords}
                    rowsPerPage={pageSize}
                    page={index}
                    onRowsPerPageChange={(e) => {
                      setIndex(0);
                      setPageSize(parseInt(e.target.value, 10));
                    }}
                    onPageChange={handleChangePage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <>
            {!loading && (
              <Grid
                sx={{
                  p: 2,
                  mt: 5,
                  borderRadius: 1,
                  bgcolor: theme.palette.secondary.main,
                }}
                width="100%"
              >
                <Typography variant="h6">
                  {strings.no_data_available}
                </Typography>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};
export default TicketList;
