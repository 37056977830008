import { FC, useState, useEffect, useCallback, useMemo, Fragment, } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../../../utils/constants/routes";
import axiosInstance from "../../../../../utils/axios";
import AddEdit from "../../../CommonPage/AddEdit";
import { AddEditTicketForm } from "./AddEditTicketForm";
import { URLS } from "../../../../../utils/constants/urls";
import strings from "../../../../../common/Translation/Translate";
import { getDrodpdownResponse } from "./TicketService";
import { createDropdownData, removeErrorFieldsFromValues } from "../../../../../utils/validators/HelperFunctions";
import { flagData } from "../../../../../utils/constants/data";
import { AlertColor, CircularProgress, Divider, Stack, Button, Box, useTheme } from "@mui/material";
import Notification from "../../../../../components/Notification";
import InputField from "../../../../../components/InputField";
import DrawerMenu from "../../../../../components/Drawer/DrawerMenu";
import TimeLine from "../../../../../components/Timeline/TimeLine";
import dayjs from "dayjs";
import PageLoader from "../../../../../components/PageLoader";
import '../ticket.scss'
// import RFQIdTable from "./RFQIdTable";
// import OrderIdTable from "./OrderIdTable";
import BuyerProfileDetails from "./ProfileDetails";


const AddEditTicket: FC = () => {
  const { id } = useParams() as any;
  const theme = useTheme();
  const Navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [ticketSource, setTicketSource] = useState<any>([]);
  const [ticketStatus, setTicketStatus] = useState<any>([]);
  const [ticketSubSource, setTicketSubSource] = useState<any>([]);
  const [ticketCategory, setTicketCategory] = useState<any>([]);
  const [ticketSubCategory, setTicketSubCategory] = useState<any>([]);
  const [issueType, setIssueType] = useState<any>([]);
  const [ticketType, setTicketType] = useState<any>([]);
  const [keyAttribute, setkeyAttribute] = useState<any>([]);
  const [childAttribute, setChildAttribute] = useState<any>([]);
  const [assignee, setAssignee] = useState<any>([]);
  const [notes, setNotes] = useState<string>('');
  const [notesTimeLine, setNotesTimeLine] = useState<any>([]);
  const [assignGroup, setAssignGroup] = useState<any>([]);
  // const [deleteFile, setDeleteFile] = useState<any>([]);
  const [rfqLineId, setRfqLineId] = useState<any>('');
  const [buyerInfoModal, setBuyerInfoModal] = useState<boolean>(false);
  const [buyerInfo, setBuyerInfo] = useState<any>({});
  // const [buyerInfoByOrderId, setBuyerInfoByOrderId] = useState<any>();
  // const [buyerInfoByRfqId, setBuyerInfoByRfqId] = useState<any>();
  const [profileModalType, setProfileModalType] = useState<string>('');
  const [ids, setIds] = useState<any>();
  const [buyerId, setBuyerId] = useState<string>('');
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: "",
  });
  const [pageResponse, setPageResponse] = useState<any>([]);
  const getInitialResponse = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(URLS.get_edit_delete_ticket(id));
      const logo = [];
      if (data?.descriptions?.length > 0) {
        const filterData = data?.descriptions.map((item: any) => ({
          preview: item?.fileUrl,
          name: item?.fileName,
          id: item?.id,
          type: item?.fileType,
        }))
        logo.push(...filterData);
      }
      setRfqLineId(data?.rfqLineId || '')
      const filter = {
        ...data,
        ticketSubCategory: data.ticketSubCategory?.ticketSubCategory ? { label: data.ticketSubCategory?.ticketSubCategory, value: data.ticketSubCategory.id } : '',
        assignGroup: { label: data.assignGroup?.assignGroup, value: data.assignGroup.id },
        assignee: data.assignee ? { label: data.assignee?.email, value: data.assignee?.id } : '',
        childAttribute: data.childAttribute?.childAttribute ? { label: data.childAttribute?.childAttribute, value: data.childAttribute.id } : '',
        keyAttribute: data.keyAttribute?.keyAttribute ? { label: data.keyAttribute?.keyAttribute, value: data.keyAttribute.id } : '',
        issueType: data.issueType?.issueType ? { label: data.issueType?.issueType, value: data.issueType.id } : '',
        ticketCategory: data.ticketCategory?.ticketCategory ? { label: data.ticketCategory?.ticketCategory, value: data.ticketCategory.id } : '',
        ticketSource: data.ticketSource?.ticketSource ? { label: data.ticketSource?.ticketSource, value: data.ticketSource.id } : '',
        ticketSubSource: data.ticketSubSource?.ticketSubSource ? { label: data.ticketSubSource?.ticketSubSource, value: data.ticketSubSource.id } : '',
        ticketStatus: data?.ticketStatus?.ticketStatus ? { label: data?.ticketStatus?.ticketStatus, value: data?.ticketStatus?.id } : '',
        ticketType: { label: data.ticketType?.ticketType, value: data.ticketType.id },
        orderIds: data.orderId ? { label: data.orderId, value: data?.orderId } : '',
        descriptions: logo || [],
        isEscalation: data?.escalation ? { label: strings.ticket_text_yes, value: 'Yes' } : { label: strings.ticket_text_no, value: 'No' },
      }
      setBuyerId(data.buyerId)

      if (data?.ticketCategory?.id) {
        getSubCategory(data.ticketCategory.id, filter, false)
      }
      if (data?.ticketSubCategory?.id) {
        getIssueTypeFromTicketSubCategory(data.ticketSubCategory.id, filter, false)
      }
      if (data?.issueType?.id) {
        getTicketTypeFromIssueType(data.issueType.id, filter, false)
      }
      if (data?.ticketSource?.id) {
        getTicketSubSource(data.ticketSource.id, filter, false)
      }
      if (data?.keyAttribute?.id) {
        getChildAttribute(data.keyAttribute.id, filter, false)
      }
      if (data?.assignGroup?.id) {
        getAssigneeUser(data.assignGroup.id, filter, false)
      }
      if (data.childAttribute?.childAttribute) {
        setProfileModalType(data.childAttribute?.childAttribute === 'Order ID' ? 'orderId' : data.childAttribute?.childAttribute === 'RFQ ID' ? 'rfqId' : '');
        // getOrderIds(data.childAttribute?.childAttribute, filter, false)
      }

      setPageResponse(filter);
    } catch (error: any) {
      console.log(error)
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );
  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);

  useEffect(() => {
    getDrodown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDrodown = async () => {
    try {
      const source = await getDrodpdownResponse(URLS.ticket_dropdown_sources)
      setTicketSource(source);
    } catch (e) {

    }
    try {
      const source = await getDrodpdownResponse(URLS.ticket_dropdown_ticket_status)
      setTicketStatus(source);
    } catch (e) {

    }
    try {
      const category = await getDrodpdownResponse(URLS.ticket_dropdown_ticket_categories);
      setTicketCategory(category);
    } catch (e) {

    }
    if (!id) {
      // try {
      //   const group = await getDrodpdownResponse(URLS.ticket_dropdown_assign_group);
      //   setAssignGroup(group);
      // } catch (e) {

      // }
      // try {
      //   const ticketType = await getDrodpdownResponse(URLS.ticket_dropdown_ticket_types);
      //   setTicketType(ticketType);
      // } catch (e) {
      // }
      // try {
      //   const subcategory = await getDrodpdownResponse(URLS.ticket_dropdown_ticket_sub_categories);
      //   setTicketSubCategory(subcategory);
      // } catch (e) {

      // }
      // try {
      //   const issuetype = await getDrodpdownResponse(URLS.ticket_dropdown_issue_types);
      //   setIssueType(issuetype);
      // } catch (e) {

      // }
      // try {
      //   const subSource = await getDrodpdownResponse(URLS.ticket_dropdown_sub_sources)
      //   setTicketSubSource(subSource);
      // } catch (e) {

      // }
      // try {
      //   const child = await getDrodpdownResponse(URLS.ticket_dropdown_child_attributes);
      //   setChildAttribute(child);
      // } catch (e) {
      // }
    }
    try {
      const attribute = await getDrodpdownResponse(URLS.ticket_dropdown_key_attributes);
      setkeyAttribute(attribute);
    } catch (e) {

    }


  }

  const customCallbackResponse = (callbackValue: any) => {
    Navigate(routes.privilige + '/' + callbackValue.id)
  }
  const values = {
    ticketSource: createDropdownData(ticketSource, ['id', 'name']),
    ticketSubSource: createDropdownData(ticketSubSource, ['id', 'name']),
    ticketStatus: createDropdownData(ticketStatus, ['id', 'name']),
    ticketCategory: createDropdownData(ticketCategory, ['id', 'name']),
    ticketSubCategory: createDropdownData(ticketSubCategory, ['id', 'name']),
    issueType: createDropdownData(issueType, ['id', 'name']),
    ticketType: createDropdownData(ticketType, ['id', 'name']),
    keyAttribute: createDropdownData(keyAttribute, ['id', 'name']),
    childAttribute: createDropdownData(childAttribute, ['id', 'name']),
    assignGroup: createDropdownData(assignGroup, ['id', 'name']),
    isEscalation: createDropdownData(flagData(strings), ['id', 'name']),
    assignee: createDropdownData(assignee, ['id', 'name']),
    orderIds: createDropdownData(ids, ['id', 'name']),
  }
  const pageConfig =
  {
    title: id ? strings.ticket_text_edit_ticket : strings.ticket_text_create_ticket,
    successMessage: id ? strings.common_update_message : strings.common_save_message,
    buttonText: id ? strings.ticket_text_update_button : strings.ticket_text_create_button,
    addEditApiUrl: id ? URLS.get_edit_delete_ticket(id) : URLS.ticket,
    initialApi: URLS.get_edit_delete_ticket(id),
    formModel: AddEditTicketForm(id, buyerId, pageResponse?.childAttribute?.label === "RFQ ID"),
    navigateToPage: routes.ticket,
    isCustomResponse: true,
    isFormData: true
  }
  const handleCustomResponse = (custom: any) => {
    const body = new FormData();
    body.append('email', custom.email);
    body.append('buyerId', custom.buyerId);
    body.append('ticketSource', custom.ticketSource.value);
    body.append('ticketSubSource', custom.ticketSubSource.value);
    body.append('ticketCategory', custom.ticketCategory.value);
    body.append('ticketSubCategory', custom.ticketSubCategory.value);
    body.append('issueType', custom.issueType.value);
    body.append('ticketType', custom.ticketType.value);
    body.append('keyAttribute', custom.keyAttribute.value);
    body.append('childAttribute', `${custom.childAttribute.value}` || '');
    body.append('assignGroup', custom.assignGroup.value);
    body.append('rfqLineId', rfqLineId || '');
    body.append('assignee', custom.assignee.value);
    body.append('ticketStatus', custom.ticketStatus.value || '');
    body.append('orderId', custom.orderIds.value || '');
    body.append('promisedResolutionDate', dayjs(custom.promisedResolutionDate).format('YYYY-MM-DD'));
    // for (let i = 0; i < custom.description?.length; i++) {
    // body.append('descriptions', custom.description || '');
    // }
    // body.append("deletedFile", deleteFile as any);
    if (custom.descriptions || pageResponse.descriptions) {
      (Array.from(custom.descriptions) || Array.from(pageResponse.descriptions)).forEach((file: any) => {
        body.append("descriptions", file);
      });
    } else {
      body.append('descriptions', null as any);
    }
    body.append('isEscalation', custom.isEscalation?.value === 'Yes' ? true : false as any);
    body.append('text', custom.text);
    // setLoading(true);
    return body
  }
  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleText = (value: string) => {
    setNotes(value)
  }
  const handleNotes = async (type: string) => {
    setBuyerInfoModal(false);
    const method = type === 'post' ? 'post' : 'get';
    const body = type === 'post' ? { note: notes } : {} as any;
    try {
      setLoading(true);
      const { status, data } = await axiosInstance[method](URLS.tickets_notes(id), body);
      if (status === 200 || status === 201) {
        setLoading(false);
        if (type === 'post') {
          setNotes('');
          setMessage({
            display: true,
            severity: "success",
            message: 'Note added successfullly',
          });
        } else {
          if (data.length > 0) {
            setNotesTimeLine(data.reverse());
            setOpenModal(true);
          } else {
            setMessage({
              display: true,
              severity: "warning",
              message: 'Data not available',
            });
          }
          //  setMessage({
          //   display: true,
          //   severity: "warning",
          //   message: 'Data not available',
          // });
        }

      }
    } catch (e) {
      setLoading(false);
    }
  }
  const getSubCategory = async (id: number, formData: any, flag: boolean) => {
    try {
      const { status, data } = await axiosInstance.get(URLS.getTicketSubCategoryFromTicketCategory(id));
      if (status === 200) {
        if (data.length > 0) {
          const array: { name: any; id: any; }[] = [];
          data[0]?.ticketSubCategoryResponse.forEach((el: any) => {
            array.push({ name: el?.ticketSubCategory, id: el.id });
          });
          setTicketSubCategory(array);
          if (flag) {
            const form = { ...formData, id: pageResponse.id, ticketSubCategory: '', issueType: '', ticketType: '', assignGroup: '' };
            setPageResponse(form);
          }

        }
      }
    } catch (e) {
    }
  }

  const getIssueTypeFromTicketSubCategory = async (id: number, formData: any, flag: boolean) => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(URLS.addIssueTypes(id));
      if (status === 200) {
        if (data?.length > 0) {
          setLoading(false);
          const array: { name: any; id: any; }[] = [];
          data[0]?.issueTypeResponse.forEach((el: any) => {
            array.push({ name: el?.issueType, id: el.id });
          });
          setIssueType(array);
          // const assigneeArray: { name: any; id: any; }[] = [];
          // data[0]?.assignGroupResponse.forEach((el: any) => {
          //   assigneeArray.push({ name: el?.assignGroup, id: el.id });
          // });
          // setAssignGroup(assigneeArray);
          // const ticketTypeArray: { name: any; id: any; }[] = [];
          // data[0]?.ticketTypeResponse.forEach((el: any) => {
          //   ticketTypeArray.push({ name: el?.ticketType, id: el.id });
          // });
          // setTicketType(ticketTypeArray);
          if (flag) {
            const form = {
              ...formData, id: pageResponse.id, issueType: array?.length > 0 ? { label: array[0]?.name, value: array[0]?.id } : '',
              // assignGroup: { label: assigneeArray[0]?.name, value: assigneeArray[0]?.id }, ticketType: { label: ticketTypeArray[0]?.name, value: ticketTypeArray[0]?.id } 
            }
            setPageResponse(form);
            if (array.length > 0) {
              getTicketTypeFromIssueType(array[0]?.id, form, flag);
            }

          }
        }
      }
    } catch (e) {
      setLoading(false);
    }
  }
  const getTicketTypeFromIssueType = async (value: number, formData: any, flag: boolean) => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(URLS.getTicket_type_from_issue_type(value));
      if (status === 200) {
        setLoading(false);
        if (data?.length > 0) {
          const assigneeArray: { name: any; id: any; }[] = [];
          data[0]?.assignGroupResponse.forEach((el: any) => {
            assigneeArray.push({ name: el?.assignGroup, id: el.id });
          });
          setAssignGroup(assigneeArray);
          const ticketTypeArray: { name: any; id: any; }[] = [];
          data[0]?.ticketTypeResponse.forEach((el: any) => {
            ticketTypeArray.push({ name: el?.ticketType, id: el.id });
          });
          setTicketType(ticketTypeArray);
          if (flag) {
            const form = {
              ...formData, id: pageResponse.id, ticketType: ticketTypeArray?.length > 0 ? { label: ticketTypeArray[0]?.name, value: ticketTypeArray[0]?.id } : '', assignGroup: assigneeArray?.length > 0 ? { label: assigneeArray[0]?.name, value: assigneeArray[0]?.id } : ''
            }
            console.log(form);
            setPageResponse(form);
            if (assigneeArray?.length > 0) {
              getAssigneeUser(assigneeArray[0]?.id, form, flag);
            }
          }
        }
      }
    } catch (e) {
      setLoading(false);
    }
  }
  const getTicketSubSource = async (id: number, formData: any, flag: boolean) => {
    try {
      const { status, data } = await axiosInstance.get(URLS.getTicketSubSourceFromTicketSource(id));
      if (status === 200) {
        if (data?.length > 0) {
          const array: { name: any; id: any; }[] = [];
          data[0]?.ticketSubSourceResponse.forEach((el: any) => {
            array.push({ name: el?.ticketSubSource, id: el.id });
          });
          setTicketSubSource(array);
          if (flag) {
            setPageResponse({ ...formData, id: pageResponse.id, ticketSubSource: '' });
          }
        }

      }
    } catch (e) {
    }
  }
  const getChildAttribute = async (id: number, formData: any, flag: boolean) => {
    console.log(formData);
    try {
      if (formData.keyAttribute.label === 'Buyer Code' || formData.keyAttribute.label === 'Buyer Email') {
        updatedChildAttribute(formData, formData.keyAttribute.label);
        return false
      }
      const { status, data } = await axiosInstance.get(URLS.getChildAttributeFromKeyAttribute(id));
      if (status === 200) {
        if (data?.length > 0) {
          const array: { name: any; id: any; }[] = [];
          data[0]?.childAttributeResponse.forEach((el: any) => {
            array.push({ name: el?.childAttribute, id: el.id });
          });
          setChildAttribute(array);
          if (flag) {
            setPageResponse({ ...formData, id: pageResponse.id, childAttribute: '' });
          }
        }
      }
    } catch (e) {
    }
  }
  const updatedChildAttribute = (formData: any, key: string) => {
    if (key === 'Buyer Code') {
      setChildAttribute([]);
      setPageResponse({ ...formData, id: pageResponse.id, childAttribute: formData.buyerId ? { label: formData.buyerId, value: formData.buyerId } : '' });
    } else if (key === 'Buyer Email') {
      setChildAttribute([]);
      setPageResponse({ ...formData, id: pageResponse.id, childAttribute: formData.email ? { label: formData.email, value: formData.email } : '' });
    }
  }
  const getAssigneeUser = async (id: number, formData: any, flag: boolean) => {
    try {
      const { status, data } = await axiosInstance.get(URLS.assigneeUser(id));
      if (status === 200) {
        setAssignee(data);
        if (flag) {
          setPageResponse({ ...formData, id: pageResponse.id, assignee: '' });
        }
      }
    } catch (e) {
    }
  }
  const getOrderIds = async (value: string, formData: any, flag: boolean) => {
    if (formData.buyerId) {
      try {
        setLoading(true);
        const { status, data } = await axiosInstance.get(value === 'Order ID' ? URLS.buyerOpenOrder(formData.buyerId) : URLS.buyeRfq(formData.buyerId));
        if (status === 200) {
          if (data === '') {
            setMessage({
              display: true,
              severity: "warning",
              message: strings.no_data_available,
            });
          }
          setLoading(false);
          // if (formData?.childAttribute?.label === 'Order ID') {
          //   setBuyerInfoByOrderId(data?.filterOpenOrderResponse?.orders);
          // } else if (formData?.childAttribute?.label === 'RFQ ID') {
          //   setBuyerInfoByRfqId(data?.filterQueryResponseDTO?.data)
          // }
          if (data?.filterQueryResponseDTO?.data?.length > 0 && formData?.childAttribute?.label === 'RFQ ID') {
            const array: { name: any; id: any; lineSheetId: any }[] = [];
            data?.filterQueryResponseDTO?.data?.forEach((el: any) => {
              if (el?.queryNumber !== null) {
                array.push({ name: el?.queryNumber, id: el.queryNumber, lineSheetId: el.lineSheetId });
              }
            });
            setIds(array);
          } else if (data?.filterPlacedOrderResponseDTO?.orders?.length > 0 && formData?.childAttribute?.label === 'Order ID') {
            const array: { name: any; id: any; }[] = [];
            data?.filterPlacedOrderResponseDTO?.orders?.forEach((el: any) => {
              if (el?.orderId !== null) {
                array.push({ name: el?.orderId, id: el.orderId });
              }
            });
            setIds(array);
          }
          if (flag) {
            setPageResponse({ ...formData, id: pageResponse.id, orderIds: '' });
          } else {

          }
        }
      } catch (e) {
        setLoading(false);
      }
    } else {
      setMessage({
        display: true,
        severity: "warning",
        message: `Please enter valid ${strings.ticket_form_text_buyer_code}`,
      });
    }
  }

  const handleOnChange = (field: string, value: any, formData: any, isDeleted: any) => {
    const formValue = removeErrorFieldsFromValues(formData);
    if (field === 'descriptions' && isDeleted === undefined) {
      // setDeleteFile(pageResponse?.descriptions[0]?.name);
      setPageResponse({ ...formData, descriptions: value });
    }
    if (field === 'buyerId') {
      setBuyerId(value);
      setPageResponse({ ...formData, id: pageResponse.id, buyerId: value, keyAttribute: '', childAttribute: '' });
    }
    if (field === 'email') {
      setPageResponse({ ...formData, id: pageResponse.id, email: value, keyAttribute: '', childAttribute: '' });
    }
    if (field === 'profile') {
      setProfileModalType('profile');
      getBuyerProfile(field, formData);
    }
    if (field === "orderInfo") {
      setProfileModalType(formData.childAttribute?.label === 'Order ID' ? 'orderId' : formData.childAttribute?.label === 'RFQ ID' ? 'rfqId' : '');
      openInfoModal(field, formData);
    }

    if (value.value) {
      switch (field) {
        case field = 'ticketCategory':
          getSubCategory(value.value, formValue, true)
          break;
        case field = 'issueType':
          getTicketTypeFromIssueType(value.value, formValue, true)
          break;
        case field = 'ticketSubCategory':
          getIssueTypeFromTicketSubCategory(value.value, formValue, true)
          break;
        case field = 'ticketSource':
          getTicketSubSource(value.value, formValue, true)
          break;
        case field = 'keyAttribute':
          getChildAttribute(value.value, formValue, true)
          break;
        case field = 'assignGroup':
          getAssigneeUser(value.value, formValue, true)
          break;
        case field = 'childAttribute':
          setProfileModalType(value.label === 'Order ID' ? 'orderId' : value.label === 'RFQ ID' ? 'rfqId' : '');
          setIds([]);
          getOrderIds(value.label, formValue, true);
          break;
        case field = 'orderIds':
          if (formData.childAttribute.label === 'RFQ ID') {
            const lineId = ids.find((item: any) => item.name === formData.orderIds.value);
            setRfqLineId(lineId.lineSheetId);
          }
          break;
        default:
          break;
      }
    }
  }

  const modalContent = useMemo(() => {
    return (
      <>
        {/* {profileModalType === 'orderId' && <OrderIdTable buyerInfoByOrderId={buyerInfoByOrderId} />} */}
        {/* {profileModalType === 'rfqId' && <RFQIdTable buyerInfoByRfqId={buyerInfoByRfqId} />} */}
        {profileModalType === 'profile' && <BuyerProfileDetails buyerInfo={buyerInfo} />}
      </>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerInfo?.buyerCode,
    // buyerInfoByOrderId, 
    // buyerInfoByRfqId, 
    profileModalType]);

  const getBuyerProfile = async (field: string, formData: any) => {
    if (buyerId) {
      try {
        setLoading(true);
        const { status, data } = await axiosInstance(URLS.buyerProfile(buyerId));
        if (status === 200) {
          setLoading(false);
          setOpenModal(true);
          setBuyerInfoModal(true)
          setBuyerInfo(data?.buyerDetails);
        }
      } catch (e: any) {
        setLoading(false);
        if (e.response) {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
    } else {
      setMessage({
        display: true,
        severity: "warning",
        message: `Please enter valid ${strings.ticket_form_text_buyer_code}`,
      });
    }
  }
  const openInfoModal = (field: string, formData: any) => {
    if (formData.orderIds?.value && formData?.childAttribute?.label === 'Order ID') {
      window.open(`${process.env.REACT_APP_REDIRECT_EXTERNAL_URL}placed-orders-detail/${formData.orderIds.value}`, '_blank', 'noreferrer');
    } else if (formData.orderIds?.value && formData?.childAttribute?.label === 'RFQ ID') {
      const lineId = ids?.find((item: any) => item.name === formData.orderIds.value);
      window.open(`${process.env.REACT_APP_REDIRECT_EXTERNAL_URL}rfq/rfqDetail?buyerId=${formData.buyerId}&rfqId=${formData.orderIds.value}&lineSheetId=${lineId?.lineSheetId || rfqLineId}`, '_blank', 'noreferrer');
    } else {
      setMessage({
        display: true,
        severity: "warning",
        message: `Please select valid Order/RFQ Id from dropdown`,
      });
    }
    // if (buyerId) {
    //   if (field === 'orderInfo' && formData.childAttribute === '') {
    //     setMessage({
    //       display: true,
    //       severity: "warning",
    //       message: 'Please enter valid child attribute value'
    //     });
    //     return false
    //   }
    //   setOpenModal(true);
    //   setBuyerInfoModal(true)
    // } else {
    //   setMessage({
    //     display: true,
    //     severity: "warning",
    //     message: `Please enter valid ${strings.ticket_form_text_buyer_code}`,
    //   });
    // }
  }
  return (
    <>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <AddEdit handlePropsChange={handleOnChange} handleCustomResponse={handleCustomResponse as any} dropdownValue={values} pageConfig={pageConfig} pageResponse={pageResponse} customCallbackResponse={customCallbackResponse} />
      {id && <>
        <Divider />
        <Stack maxWidth='500px' direction='column' sx={{ mt: 3 }}>
          <InputField inputValue={notes} label="Add Note" multiline rows={3} textChange={handleText} />
        </Stack>
        <Button onClick={() => handleNotes('post')} disabled={notes.length < 1} variant="contained" component="label">
          {loading && <CircularProgress color="inherit" sx={{ mr: 1 }} size={20} />}
          Add
        </Button>
        <Button color="secondary" sx={{ ml: 2 }} onClick={() => handleNotes('get')} variant="contained" component="label">
          {loading && <CircularProgress color="inherit" sx={{ mr: 1 }} size={20} />}
          {strings.note_text_title}
        </Button>

      </>}
      <DrawerMenu width="70%" open={openModal} setOpen={() => setOpenModal(false)} >
        <Box p={3}>
          {buyerInfoModal ?
            modalContent
            : <TimeLine primary={theme.palette.primary.light} title={strings.note_text_title} fontFamiliy={theme.typography.fontFamily} comments={notesTimeLine} />
          }
        </Box>
      </DrawerMenu>
    </>);
};
export default AddEditTicket;