import strings from "../../../common/Translation/Translate";
import { FormModel } from "../../../components/Form";
import { dataFlagDropdown } from "../../../utils/constants/data";

export const FilterForm = (): FormModel[] => {
  return [
    {
      label: strings.ticket_form_text_buyer_code,
      value: "",
      size: "medium",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "buyerId",
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
    {
      label: strings.ticket_form_text_ticket_id,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "ticketId",
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
    {
      label: `${strings.ticket_form_text_is_escalation} *`,
      value: "",
      size: "medium",
      type: "drop-down",
      placeholder: "",
      field: "isEscalation",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      options: dataFlagDropdown(strings),
    },
    {
      label: `${strings.ticket_form_text_is_email} *`,
      value: "",
      size: "medium",
      type: "text",
      placeholder: "",
      field: "email",
      validators: [],
      responsive: { xs: 12 },
      required: true,
      sx: { mt: 1 },
    },
    // {
    //   label: `${strings.ticket_form_text_sort_by} *`,
    //   value: "",
    //   size: "medium",
    //   type: "drop-down",
    //   placeholder: "",
    //   field: "sortBy",
    //   validators: [
    //   ],
    //   responsive: { xs: 12 },
    //   required: true,
    //   options: sortBy(strings),
    //   sx:{mt:1}
    // }
  ];
};
