import { Validators } from "../../../utils/validators";
import { FormModel } from "../../../components/Form";
import strings from "../../../common/Translation/Translate";
export const AddUserForm = (): FormModel[] => {
    return [
        {
            label: "First Name *",
            value: "",
            size: "medium",
            autoFocus: true,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "firstName",
            validators: [
                { check: Validators.required, message: "This field is mandatory" },
            ],
            responsive: { xs: 4 },
            required: true,
        },
        {
            label: "Last Name *",
            value: "",
            size: "medium",
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "lastName",
            validators: [
                { check: Validators.required, message: "This field is mandatory" },
            ],
            responsive: { xs: 4 },
            required: true,
        },
        {
            label: "Email ID *",
            value: "",
            size: "medium",
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "email",
            validators: [
                { check: Validators.required, message: "This field is mandatory" },
                {
                    check: Validators.email,
                    message: "Please enter a valid email ID",
                },
            ],
            responsive: { xs: 4 },
            required: true,
        },
        {
            label: "Phone Number *",
            value: "",
            size: "medium",
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "phone",
            validators: [
                { check: Validators.required, message: "This field is mandatory" },
                {
                    check: Validators.phoneNumber,
                    message: "Please enter a valid phone number",
                },
            ],
            responsive: { xs: 4 },
            required: true,
        },
        {
            label: `Roles *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `Roles`,
            field: "roles",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 4 },
            required: true,
            isMulti: true
        },
        {
            label: `${strings.updateProfile_text_user_group} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.updateProfile_text_user_group} *`,
            field: "groups",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 4 },
            required: true,
            isMulti: true
        },
        {
            label: `${strings.updateProfile_text_pinCode} *`,
            value: "",
            size: "medium",
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "pinCode",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 4 },
            required: true,
        },
        {
            label: `${strings.updateProfile_text_address} *`,
            value: "",
            size: "medium",
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "address",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 4 },
            required: true,
        },
        {
            label: `${strings.updateProfile_text_language} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: strings.updateProfile_text_language,
            field: "language",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 4 },
            required: true,
            isMulti: false
        },
        // {
        //     label: `${strings.updateProfile_text_country} *`,
        //     value: "",
        //     size: "medium",
        //     type: "drop-down",
        //     placeholder: "",
        //     field: "country",
        //     validators: [
        //         { check: Validators.required, message: "This field is mandatory" },
        //     ],
        //     responsive: { xs: 4 },
        //     required: true,
        //     options: country,
        // },
        // {
        //     label: `${strings.updateProfile_text_state} *`,
        //     value: "",
        //     size: "medium",
        //     type: "drop-down",
        //     placeholder: "",
        //     field: "state",
        //     validators: [
        //         { check: Validators.required, message: "This field is mandatory" },
        //     ],
        //     responsive: { xs: 4 },
        //     required: true,
        //     options: states,
        // },
        // {
        //     label: `${strings.updateProfile_text_city} *`,
        //     value: "",
        //     size: "medium",
        //     type: "drop-down",
        //     placeholder: "",
        //     field: "city",
        //     validators: [
        //         { check: Validators.required, message: "This field is mandatory" },
        //     ],
        //     responsive: { xs: 4 },
        //     required: true,
        //     options: cities,
        // },
    ];
};
