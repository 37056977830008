import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';


type TabularProps = {
    children: any,
    rows: string[]
}
export default function Tabular({ children, rows }: TabularProps) {
    const theme=useTheme()
    return (
        <TableContainer component={Paper}>
            <Table stickyHeader aria-label="table">
                <TableHead sx={{'.MuiTableCell-root':{
                    backgroundColor:theme.palette.secondary.main,
                    border: `1px solid ${theme.palette.secondary.main}`
                }}}>
                    <TableRow>          
                        {rows.map((item: string, i:number) =>
                            <TableCell sx={{whiteSpace:'nowrap'}} key={i}>{item}</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                </TableBody>
            </Table>
        </TableContainer>
    );
}