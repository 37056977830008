import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../../utils/axios";
import AddEdit from "../../../../CommonPage/AddEdit";
import { AddNewUserGroupsForm } from "./AddNewUserGroupsForm";
import { URLS } from "../../../../../../utils/constants/urls";
import strings from "../../../../../../common/Translation/Translate";
import { routes } from "../../../../../../utils/constants/routes";

const AddNewUserGroups: FC = () => {
  const { id } = useParams() as any;
  const Navigate = useNavigate();
  const handleSecondaryButton = () => {
    Navigate(`/ticketTypes/addNewGroup`)
  }
  const [pageResponse, setPageResponse] = useState<any>([]);

  const getInitialResponse = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(
        URLS.addNewUserGroups
      );
      setPageResponse(data);
    } catch (error: any) { }
  }, []);

  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);
  
  const pageConfig = {
    title: id
      ? strings.assign_create_a_new_user_group_edit
      : strings.assign_create_a_new_user_group_add,
    successMessage: id
      ? strings.common_update_message
      : strings.common_save_message,
    addEditApiUrl: id
      ? URLS.get_edit_delete_assign_groups(id)
      : URLS.addAssignGroups,
    initialApi: URLS.editRole(id),
    formModel: AddNewUserGroupsForm(strings),
    navigateToPage: routes.assignGroups,
    isCustomResponse: false,
    // secondaryButtonText: strings.ticket_types_title_add
  };
  console.log('Create New User Grouos============',pageConfig);
  return <AddEdit pageConfig={pageConfig} pageResponse={pageResponse} handleSecondaryButton={handleSecondaryButton} />;
};
export default AddNewUserGroups;
