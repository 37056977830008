import axiosInstance from "../../../../../utils/axios";

export const getDrodpdownResponse = async (urls: string) => {
    try {
        // const body = { page: 1, size: 1000, filter: [], sort: [] };
        const { status, data } = await axiosInstance.get(urls);
        if (status === 200) {
            return data;
        }
    } catch (e) {
        throw e;
    }
};