import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";

export const getRoles = async () => {
    try {
        // const body = { page: 1, size: 1000, filter: [], sort: [] };
        const { status, data } = await axiosInstance.get(URLS.roles);
        if (status === 200) {
            return data;
        }
    } catch (e) {
        throw e;
    }
};