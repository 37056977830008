import { createSlice } from "@reduxjs/toolkit";
import {
  clearStorage,
  fetchFromStorage,
  removeFromStorage,
  saveToStorage,
} from "../../storage";
import { identifiers } from "../../constants/identifiers";

const fromStorage = fetchFromStorage(identifiers.access_token);

interface AuthenticationState {
  user: any;
  authenticated: boolean;
  privilege: any;
  translation: any;
  languageList:[] | null;
  notificationCount?: number
}

const authenticationSlice = createSlice({
  name: "authenticationSlice",
  initialState: {
    authenticated: fromStorage || false,
    user: null,
    notificationCount:0,
    privilege: fetchFromStorage(identifiers.privilegeList),
    translation: fetchFromStorage(identifiers.translation),
    languageList: fetchFromStorage(identifiers.languages) && JSON.parse(fetchFromStorage(identifiers.languages) as any),
  },
  reducers: {
    setAuthentication(state, action) {
      if (action.payload) {
        // const decoded = jwt_decode(action.payload.token) as any;
        // console.log(decoded);
        saveToStorage(identifiers.access_token, action.payload.access_token);
        saveToStorage(identifiers.privilegeList, action.payload.privileges);
        saveToStorage(identifiers.refresh_token, action.payload.refresh_token);
        state.authenticated = true;
        state.privilege = action.payload.privileges
      } else {
        removeFromStorage(identifiers.access_token);
        state.authenticated = false;
      }
    },
    logout(state) {
      clearStorage("all");
      state.authenticated = false;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setTranslation(state, action) {
      state.translation = action.payload;
    },
    setLanguageList(state, action) {
      saveToStorage(identifiers.languages, JSON.stringify(action.payload));
      state.languageList = action.payload;
    },
    setNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },
  },
});

export default authenticationSlice.reducer;

export const { setAuthentication, logout, setUser, setTranslation,setLanguageList,setNotificationCount } =
  authenticationSlice.actions;

export const User = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.user;
export const Privilege = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.privilege;
export const translation = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.translation;
  export const languages = (state: { authenticationSlice: AuthenticationState }) =>
  state.authenticationSlice.languageList;

export const Authenticated = (state: {
  authenticationSlice: AuthenticationState;
}) => state.authenticationSlice.authenticated;
export const notificationCount = (state: {
  authenticationSlice: AuthenticationState;
}) => state.authenticationSlice.notificationCount;
