import React from 'react';
import Chart from 'react-google-charts';
import PropTypes from 'prop-types';

const Charts = ({ type, data, options, id, height }) => {
  const ref = React.createRef();
  return (
    <>
      <div ref={ref}>
        <Chart
          height={height}
          key={id}
          className="charts"
          chartType={type}
          loader={<div className="p-2">Loading Chart</div>}
          data={data}
          options={options}
          rootProps={{ 'data-testid': id }}
        />
      </div>
    </>
  );
};
export default Charts;

Charts.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  isExport: PropTypes.bool,
};
