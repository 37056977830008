import React, { FC, useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AddEdit from "../../../../CommonPage/AddEdit";
import { AddEditTicketSubCategoriesMappingForm } from "./AddEditTicketSubCategoriesMapping";
import { URLS } from "../../../../../../utils/constants/urls";
import strings from "../../../../../../common/Translation/Translate";
import { routes } from "../../../../../../utils/constants/routes";
import axiosInstance from "../../../../../../utils/axios";
import Tabular from "../../../../../../components/Tabular/Tabular";
import { Divider, TableCell, TableRow, Typography } from "@mui/material";
import { createDropdownData, fetchIdsFromSelectedDropdownValues } from "../../../../../../utils/validators/HelperFunctions";

const AddTicketSubCategoriesMapping: FC = () => {
  const { id } = useParams() as any;
  const params = useLocation().search as any;
  const paramsId = new URLSearchParams(params).get('id') as string;
  const [pageResponse, setPageResponse] = useState<any>([]);
  const [ticketType, setTicketType] = useState<any>([]);
  const [assignGroup, setAssignGroup] = useState<any>([]);


  const getMappingData = useCallback(async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.issueTypesMapping(paramsId));
      if (status === 200) {
        setPageResponse(data)
      }
    } catch (e) {
    }
  }, [paramsId])

  useEffect(() => {
    getMappingData();
  }, [getMappingData])


  const getTicketType = useCallback(async () => {
    const { data } = await axiosInstance.get(URLS.ticket_dropdown_ticket_types);
    setTicketType(data);
  }, [])
  const getAssigneeGroup = useCallback(async () => {
    const { data } = await axiosInstance.get(URLS.ticket_dropdown_assign_group);
    setAssignGroup(data);
  }, [])
  useEffect(() => {
    getTicketType();
    getAssigneeGroup();
  }, [getTicketType, getAssigneeGroup])

  const drodpdownValues = {
    ticketType: createDropdownData(ticketType, ['id', 'name']),
    assignGroup: createDropdownData(assignGroup, ['id', 'name']),
  }
  const pageConfig = {
    title: strings.ticket_subcategory_mapping_title,
    successMessage: strings.common_save_message,
    addEditApiUrl: URLS.ticketSubCategoryMapping(paramsId),
    initialApi: URLS.editRole(id),
    formModel: AddEditTicketSubCategoriesMappingForm(strings),
    navigateToPage: routes.ticketSubCategories,
    isCustomResponse: false,
  };
  const handleCustomResponse = (custom: any) => {
    if (custom) {
      return { ...custom, id: pageResponse.id, ticketType: custom?.ticketType?.value || '', groups: fetchIdsFromSelectedDropdownValues(custom.assignGroup) }
    }
  }
  return (<><AddEdit dropdownValue={drodpdownValues} pageConfig={pageConfig} pageResponse={[]} handleCustomResponse={handleCustomResponse} />
    <Tabular children={<TableRow>
      <TableCell sx={{ whiteSpace: 'nowrap' }} >
        {pageResponse[0]?.issueTypeResponse?.map((item: any, i: number) =>
          <>
            <Typography key={i}>{item.issueType || '-'}</Typography>
            <Divider sx={{ my: 1.5 }} />
          </>
        )}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} >
        {pageResponse[0]?.issueTypeResponse?.map((item: any, i: number) =>
          <>
            {item.ticketTypeResponse.map((ticket: any) =>
              <>
                <Typography key={i}>{ticket.ticketType}</Typography>
                <Divider sx={{ my: 1.5 }} />
              </>
            )}

          </>
        )}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }} >
        {pageResponse[0]?.issueTypeResponse?.map((item: any, i: number) =>
          <>
            <Typography sx={{width:'50px'}} key={i}>
              {item.assignGroupResponse.map((assinee: any) => assinee.assignGroup).join(', ')}</Typography>
            <Divider sx={{ my: 1.5 }} />
          </>
        )}
      </TableCell>

    </TableRow>} rows={[strings.menu_text_issue_types, strings.menu_text_ticket_types, strings.menu_text_assign_groups]} />
  </>
  );
};
export default AddTicketSubCategoriesMapping;
