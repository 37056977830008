import { FormModel } from "../../../components/Form";

export const UserFilter = (strings: any): FormModel[] => {
  return [
    {
      label: strings.usermgmt_text_firstName,
      value: "",
      size: "medium",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "firstName",
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
    {
      label: strings.usermgmt_text_lastName,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "lastName",
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
    {
      label: strings.usermgmt_text_phone,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "phone",
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },
    {
      label: strings.usermgmt_column_email,
      value: "",
      size: "medium",
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "email",
      validators: [],
      responsive: { xs: 12 },
      required: false,
    },

  ];
};
