import {Divider, Grid, Link, Typography } from "@mui/material";
import '../ticket.scss'

const BuyerProfileDetails = ({buyerInfo}:any) => {
    return(
        <>
        <Typography variant="h5">Profile Details</Typography>
        <Divider sx={{my:2}}/>
        <Grid mb={2} container justifyContent='space-between'>
          <Grid item sm={6}>
          <Typography fontWeight="bold">Name</Typography>
          <Typography>{`${buyerInfo?.firstName || '-'} ${buyerInfo?.lastName || '-'}`}</Typography>
          </Grid>
          <Grid item sm={6}>
          <Typography fontWeight="bold">Buyer Code</Typography>
          <Typography>{buyerInfo?.buyerCode|| '-'}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{mb:2}}/>
        <Grid mb={2} container justifyContent='space-between'>
          <Grid item sm={6}>
          <Typography fontWeight="bold">Email Id</Typography>
          <Typography>{buyerInfo?.email || '-'}</Typography>
          </Grid>
          <Grid item sm={6}>
          <Typography fontWeight="bold">Org. Phone</Typography>
          <Typography>{buyerInfo?.orgPhone|| '-'}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{mb:2}}/>
        <Grid mb={2} container justifyContent='space-between'>
          <Grid item sm={6}>
          <Typography fontWeight="bold">Org. Name</Typography>
          <Typography>{buyerInfo?.orgName}</Typography>
          </Grid>
          <Grid item sm={6}>
          <Typography fontWeight="bold">Org. Type</Typography>
          <Typography>{buyerInfo?.orgType|| '-'}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{mb:2}}/>
        <Grid mb={2} container justifyContent='space-between'>
          <Grid item sm={6}>
          <Typography fontWeight="bold">Country</Typography>
          <Typography>{buyerInfo?.country}</Typography>
          </Grid>
          <Grid item sm={6}>
          <Typography fontWeight="bold">Zip Code</Typography>
          <Typography>{buyerInfo?.zipCode|| '-'}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{mb:2}}/>
        <Link target="_blank"  rel="noopener" fontSize='20px' color="primary" href={`${process.env.REACT_APP_REDIRECT_EXTERNAL_URL}search`}>More details</Link>
        </>
      );
};
export default BuyerProfileDetails;