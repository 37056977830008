import React, { FC, useCallback, useEffect, useState } from "react";
import { routes } from "../../../../utils/constants/routes";
import ListPage from "../../CommonPage";
import { URLS } from "../../../../utils/constants/urls";
import { privileges } from "../../../../utils/constants/privileges";
import axiosInstance from "../../../../utils/axios";
import strings from "../../../../common/Translation/Translate";
import { images } from "../../../../utils/constants/images";
import { useNavigate } from "react-router-dom";
import { FilterForm } from "../FilterForm";


const RoleList: FC = () => {
  const navigate = useNavigate()
  const [pageResponse, setPageResponse] = useState<any[]>([]);
  const [sortValue, setSortValue] = useState<any>({ sortOrder: -1, sortBy: '' });
  const handleCustomImageMethod = (id: number) => {
    navigate(routes.privilige + '/' + id)
  }

  const sortHandler = (selectedValue: string) => {
    console.log(selectedValue);
    setSortValue((prev: any) => ({
      ...prev,
      sortBy: selectedValue,
      sortOrder: prev.sortOrder === 1 ? -1 : 1,
    }));
  }
  const pageConfig = {
    title: strings.rolesmgmt_title_roles,
    AddButtonText: strings.rolesmgmt_button_add,
    AddButtonUrl: routes.addRole,
    editButtonUrl: routes.editRole,
    listApi: URLS.role,
    deleteApi: URLS.deleteRole,
    editPrivilege: privileges.edit_role,
    deletePrivilege: privileges.delete_role,
    addPrivilege: privileges.add_role,
    customImage: {
      imageUrl: images.privilegeIcon,
      handleCustomImageMethod,
      privilege: privileges.edit_privileges
    },
    filterForm: FilterForm(),
    sortHandler: sortHandler,
    sortValue: sortValue,
    tableColumn: [
      { field: 'name', headerName: strings.rolesmgmt_column_name, flex: 6, sortable: true },
    ]
  }


  const getList = useCallback(async (filterData?: any) => {
    try {
      const body = filterData ? filterData : { page: 1, size: 10, filter: [], sort: ['createdAt:desc'] };
      const { data } = await axiosInstance.post(pageConfig.listApi, body);
      setPageResponse(data);
    } catch (error: any) { }
  }, [pageConfig.listApi]);

  useEffect(() => {
    (async () => {
      await getList(null);
    })();
  }, [getList]);

  return <ListPage pageConfig={pageConfig} pageResponse={pageResponse} getList={getList} />
};
export default RoleList;
