import React, { FC, useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  AlertColor,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import axiosInstance from '../../../../utils/axios';
import { URLS } from '../../../../utils/constants/urls';
import Notification from '../../../../components/Notification';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../../../../components/Button/PrimaryButton';
import Checkbox from '@mui/material/Checkbox';
import './role.scss'
import { clearStorage, fetchFromStorage } from '../../../../utils/storage';
import { identifiers } from '../../../../utils/constants/identifiers';
import { useDispatch } from 'react-redux';
import { routes } from "../../../../utils/constants/routes";
import { setAuthentication } from '../../../../utils/redux';
import { Roles } from "../../../../utils/constants/data";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
    children={props.children}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));



const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Privilege: FC = () => {
  const { id } = useParams() as any;
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<any>(1);
  const [pageResponse, setPageResponse] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [selectAll, setSelectAll] = useState<any>(false);
  const [selectedValue, setSelectedValue] = useState<any>([]);
  const [message, setMessage] = useState<{
    display: boolean;
    severity: AlertColor | null;
    message: string;
  }>({
    display: false,
    severity: null,
    message: "",
  });
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const getPrivileges = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.editRole(id));
      setLoading(false);
      const dataObject = data.privileges;
      const array = [...selectedValue];
      if (dataObject) {
        for (let i = 0; i < dataObject.length; i++) {
          if (dataObject[i].selected === true) {
            array.push(dataObject[i].id);
          }
        }
        if (dataObject.length === array.length) {
          setSelectAll(true);
        }
      }
      const filterValue = array.filter(function (item, pos) {
        return array.indexOf(item) === pos;
      });
      setSelectedValue(filterValue);
      setPageResponse(data);
    } catch (error: any) {
      setLoading(false);
      if (error.response) {
        setMessage({
          display: true,
          severity: "warning",
          message: error.response.data.message,
        });
      }
    }
  }, [id, selectedValue]);

  useEffect(() => {
     getPrivileges();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeNotification = (value: boolean) => {
    setMessage({ ...message, display: value });
  };

  const handleInputChange = (e: any) => {
    const itemName = e.target.name;
    const { checked } = e.target;
    let dataObject = pageResponse.privileges;
    if (itemName === "checkAll") {
      setSelectAll(checked);
      dataObject = dataObject.map((item: any) => ({
        ...item,
        selected: checked,
      }));
    } else {
      dataObject = dataObject.map((item: any) =>
        item.name === itemName ? { ...item, selected: checked } : item
      );
      setSelectAll(dataObject.every((item: any) => item.selected));
    }
    const arrayObj = { ...pageResponse, privileges: dataObject };
    const array = [];
    for (let i = 0; i < arrayObj.privileges.length; i++) {
      if (arrayObj.privileges[i].selected === true) {
        array.push(arrayObj.privileges[i].id);
      }
    }
    setSelectedValue(array);
    setPageResponse(arrayObj);
  };

  const handleSave = async () => {
    const jsonData = {
      name: pageResponse.name,
      privilegeIds: selectedValue,
    };
    const { status } = await axiosInstance.put(
      URLS.updatePriviliges(id),
      jsonData
    );
    if (status === 200) {
      setMessage({
        display: true,
        message: `Privilege updated successfully`,
        severity: "success",
      });
      setTimeout(() => {
        Navigate(routes.roleList);
      }, 1000);
      await refreshToken();
    }
  };

  const refreshToken = async () => {
    try {
      const refreshToken = fetchFromStorage(identifiers.refresh_token);
      if (refreshToken) {
        const body = new URLSearchParams();
        body.append('refresh_token', refreshToken);
        body.append('scope', 'webclient');
        body.append('grant_type', 'refresh_token');
        const config: any = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          auth: {
            username: process.env.REACT_APP_CLIENT,
            password: process.env.REACT_APP_SECRET,
          },
        };
        setLoading(true);
        clearStorage();
        const { status, data } = await axiosInstance.post(URLS.login, body, config);
        if (status === 200) {
          setLoading(false);
          dispatch(setAuthentication(data));
          return { status: true };
        }
      }
      return { status: false };
    } catch (e: any) {
      setLoading(false);
      return { status: false };
    }
  }


  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <Container sx={{ p: "1rem" }} maxWidth={false} className="privileges">
      <Grid item md={12}>
        <Grid justifyContent="space-between" container sx={{ mt: 3 }}>
          <Typography sx={{ fontSize: 24, fontWeight: 500 }} mb={2}>
            Role ({pageResponse?.name})
          </Typography>
          <Grid item>
            <PrimaryButton
              variant="contained"
              onClick={handleSave}
              fullWidth={false}
              sx={{ color: "#ffffff", py: 0 }}
            >
              {loading && <CircularProgress color="inherit" sx={{ mr: 1 }} size={20} />} Save
            </PrimaryButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3}>
        <FormGroup sx={{ ml: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleInputChange}
                checked={selectAll}
                name="checkAll"
                {...label}
              />
            }
            label="Select All"
          />
        </FormGroup>
      </Grid>
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      {Roles.map((item: any, key: number) => (
        <Accordion
          key={key}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{item.name}</Typography>
          </AccordionSummary>
          {pageResponse?.privileges?.length > 0 && pageResponse?.privileges
            ?.filter((filter: any) => filter.groupName === item.name)
            .map((privilege: any) => (
              <AccordionDetails key={privilege.id}>
                <Typography>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={privilege.name}
                          onChange={handleInputChange}
                          name={privilege.name}
                          key={privilege.id}
                          {...label}
                          defaultChecked={privilege.selected}
                          checked={privilege.selected}
                        />
                      }
                      label={privilege.name}
                    />
                  </FormGroup>
                </Typography>
              </AccordionDetails>
            ))}
        </Accordion>
      ))}
    </Container>
  );
};

export default Privilege;
