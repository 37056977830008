import React, { FC } from 'react';
// import '../../../inquiryForm.scss';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './timeline.scss';
import { Scrollbar } from 'react-scrollbars-custom';
import RelativeTime from 'dayjs/plugin/relativeTime';
// import moment from 'moment';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
dayjs.extend(RelativeTime);
interface OwnProps {
  comments?: any[];
  fontFamiliy?: string;
  title?:string;
  primary?:string
}
const TimeLine: FC<OwnProps> = ({ comments, fontFamiliy ,title, primary}) => {
  return (
    <div>
      <Scrollbar style={{ width: '100%', height: '95vh', fontFamily: fontFamiliy }}>
        <Typography mb={2} variant='h6'>{title}</Typography>
        <div className="timeline">
          <div className="row">
            <div className="col-sm-12">
              <VerticalTimeline layout="1-column-left">
                {comments && comments?.length &&
                  comments.map(((event: any, i: number) => (
                    <VerticalTimelineElement
                      key={i}
                      className="vertical-timeline-element--work"
                      iconStyle={{ background: primary|| 'rgb(33, 150, 243)', color: '#fff' }}>
                      <h5
                        className="vertical-timeline-element-title"
                        dangerouslySetInnerHTML={{ __html: event.note }}
                      />
                      <div>
                        {/* <img src={images.time} alt="timeline" /> */}
                        <span className="fw-600 textColor mb-2">
                          {event && dayjs(event?.createdAt).fromNow()}
                          &nbsp;by&nbsp; {event?.commentBy}
                        </span>
                      </div>
                    </VerticalTimelineElement>
                  )))}
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </Scrollbar>
    </div>
  );
};

export default TimeLine;
