import { Validators } from "../../../../../../utils/validators";
import { FormModel } from "../../../../../../components/Form";
export const AddEditTicketSubCategoriesMappingForm = (strings: any): FormModel[] => {
  return [
    {
      label: `${strings.issue_types_title_add} *`,
      value: "",
      size: "medium",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "issueType",
      validators: [
        { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 4 },
      required: true,
    },

    {
      label: `${strings.ticket_types_title_add} *`,
      value: "",
      size: "medium",
      type: "select",
      placeholder: `${strings.ticket_types_title_add} *`,
      field: "ticketType",
      validators: [
          { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 4 },
      required: true,
      isMulti: false
  },
    {
      label: `${strings.assign_groups_title_add} *`,
      value: "",
      size: "medium",
      type: "select",
      placeholder: `${strings.assign_groups_title_add} *`,
      field: "assignGroup",
      validators: [
          { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 4 },
      required: true,
      isMulti: true
  },
  ];
};
