import React, { FC, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../../../utils/axios";
import AddEdit from "../../../../CommonPage/AddEdit";
import { AddEditTicketStatusForm } from "./AddEditTicketStatusForm";
import { URLS } from "../../../../../../utils/constants/urls";
import strings from "../../../../../../common/Translation/Translate";
import { routes } from "../../../../../../utils/constants/routes";

const AddEditTicketStatus: FC = () => {
  const { id } = useParams() as any;
  const [pageResponse, setPageResponse] = useState<any>([]);

  const getInitialResponse = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(
        URLS.get_edit_delete_ticket_status(id)
      );
      setPageResponse(data);
    } catch (error: any) {}
  }, [id]);

  useEffect(() => {
    (async () => {
      if (id) {
        await getInitialResponse();
      }
    })();
  }, [getInitialResponse, id]);

  const pageConfig = {
    title: id
      ? strings.ticket_status_title_edit
      : strings.ticket_status_title_add,
    successMessage: id
      ? strings.common_update_message
      : strings.common_save_message,
    addEditApiUrl: id
      ? URLS.get_edit_delete_ticket_status(id)
      : URLS.addTicketStatus,
    initialApi: URLS.editRole(id),
    formModel: AddEditTicketStatusForm(strings),
    navigateToPage: routes.ticketStatus,
    isCustomResponse: false,
  };

  return <AddEdit pageConfig={pageConfig} pageResponse={pageResponse} />;
};
export default AddEditTicketStatus;
