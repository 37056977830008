import { FC } from 'react';
import Drawer from '@mui/material/Drawer';

type DrawerProps = {
  open: boolean,
  setOpen: () => void,
  children: any,
  width?: string
}
const DrawerMenu: FC<DrawerProps> = ({
  open,
  setOpen,
  width='50%',
  children
}) => {
  return (
    <Drawer
      PaperProps={{
        sx: {
          width: width,
        },
      }}
      anchor='right'
      open={open}
      onClose={setOpen}
    >
      {children}
    </Drawer>
  );
};

export default DrawerMenu;
