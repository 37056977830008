import { Validators } from "../../../../../utils/validators";
import { FormModel } from "../../../../../components/Form";
import strings from "../../../../../common/Translation/Translate";
export const AddEditTicketForm = (id: any, buyerId: string, isRfq: boolean): FormModel[] => {
    return [
        {
            label: strings.ticket_form_text_buyer_code,
            value: "",
            size: "medium",
            autoFocus: true,
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "buyerId",
            validators: [],
            responsive: { xs: 3 },
            required: false,
            disabled: id,
        },
        {
            children: 'Profile details',
            label: 'Profile',
            value: "",
            type: "button",
            field: "profile",
            responsive: { xs: 2 },
            disabled: id,
            variant: "outlined",
            styleClass: 'profile_button'
        },
        {
            label: strings.ticket_form_text_buyer_email,
            value: "",
            size: "medium",
            type: "text",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "email",
            validators: [{
                check: Validators.email,
                message: "Please enter a valid email ID",
            },],
            responsive: { xs: 5 },
            required: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_ticket_category} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_ticket_category} *`,
            field: "ticketCategory",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_ticket_sub_category} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_ticket_sub_category} *`,
            field: "ticketSubCategory",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_issue_type} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_issue_type} *`,
            field: "issueType",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_ticket_type} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_ticket_type} *`,
            field: "ticketType",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_ticket_source} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_ticket_source} *`,
            field: "ticketSource",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_ticket_sub_source} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_ticket_sub_source} *`,
            field: "ticketSubSource",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_key_attribute} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_key_attribute} *`,
            field: "keyAttribute",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_child_attribute} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_child_attribute} *`,
            field: "childAttribute",
            validators: [
                // { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: false,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${isRfq ? strings.ticket_form_text_RfqIds : strings.ticket_form_text_OrderIds}`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${isRfq ? strings.ticket_form_text_RfqIds : strings.ticket_form_text_OrderIds}`,
            field: "orderIds",
            validators: [
                // { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 3 },
            required: false,
            isMulti: false,
            disabled: buyerId === '' || id,
        },
        {
            children: 'Order Details',
            label: 'Order Details',
            value: "",
            type: "button",
            field: "orderInfo",
            responsive: { xs: 2 },
            disabled: id,
            variant: "outlined",
            styleClass: 'order_info_button'
        },
        {
            label: `${strings.ticket_form_text_assign_to_group} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_assign_to_group} *`,
            field: "assignGroup",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_assignee} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_assignee} *`,
            field: "assignee",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false,
            disabled: id,
        },
        {
            label: `${strings.ticket_form_text_promised_resolution_date} *`,
            value: "",
            size: "small",
            autoFocus: true,
            type: "date",
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "promisedResolutionDate",
            validators: [],
            responsive: { xs: 12, md: 5 },
            required: true,
            disabled: id,
        },

        {
            label: `${strings.ticket_form_text_is_escalation} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_is_escalation} *`,
            field: "isEscalation",
            validators: [
                { check: Validators.required, message: strings.requiredText },
            ],
            responsive: { xs: 12, md: 5 },
            required: true,
            isMulti: false
        },
        {
            label: `${strings.ticket_form_text_ticket_status} *`,
            value: "",
            size: "medium",
            type: "select",
            placeholder: `${strings.ticket_form_text_ticket_status} *`,
            field: "ticketStatus",
            validators: id ? [
                { check: Validators.required, message: strings.requiredText },
            ] : [],
            responsive: { xs: 12, md: 5 },
            required: id ? true : false,
            isMulti: false,
            disabled: id ? false : true,
        },
        {
            type: "divider",
            field: 'divder',
            responsive: { xs: 12, md: 10 },
            required: false,
            sx: { m: 2 },
            label: '',
            validators: [],
            value: ''
        },
        {
            label: strings.ticket_form_text_desciption,
            value: "",
            size: "medium",
            type: "text",
            multiline: true,
            rows: '3',
            typeValue: "text",
            variant: "outlined",
            placeholder: "",
            field: "text",
            validators: [],
            responsive: { xs: 8 },
            required: false,
            disabled: id
        },
        {
            label: '',
            value: "",
            type: "file",
            placeholder: ``,
            field: "descriptions",
            validators: [
            ],
            sx: { alignSelf: 'flex-start' },
            responsive: { xs: 12, md: 6 },
            required: false,
            multiline: true,
            disabled: id
        },
        // {
        //     label: '',
        //     value: "",
        //     type: "file",
        //     field: "description",
        //     validators: [],
        //     responsive: { xs: 8 },
        //     required: false,
        //     disabled: id
        // },
    ];
};
