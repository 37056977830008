import React, { useRef } from 'react';
import SockJsClient from 'react-stomp';
import { fetchFromStorage } from '../../utils/storage';
import { identifiers } from '../../utils/constants/identifiers';

const WebSocket = ({ message }) => {
  let webSocket = useRef();
  const token = fetchFromStorage(identifiers.access_token);
  const disconnect = error => {
    console.log(error);
    // eslint-disable-next-line no-unused-expressions
    webSocket?.disconnect();
  };

  return (
    <SockJsClient
      autoReconnect
      url={`${process.env.REACT_APP_BASE_API_URL}/websocket`}
      headers={{ Authorization: `Bearer ${token}` }}
      topics={['/user/queue/tasks', '/user/queue/notifications']}
      onMessage={(msg, topic) => {
        message(msg, topic);
      }}
      onConnectFailure={error => {
        console.log(error);
        disconnect(error);
      }}
      ref={el => (webSocket = el)}
    />
  );
};
export default WebSocket;
