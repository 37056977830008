import { Button, useTheme } from "@mui/material";
import React, { FC } from "react";
import { ToolTip } from "../Tooltip/ToolTip";
import "./styles.css";

type PrimaryButtonProps = {
  onClick?: any;
  disabled?: boolean;
  children: React.ReactNode | string;
  variant?: any;
  className?: string;
  fullWidth?: boolean;
  title?: string;
  sx?: Object;
  type?: any;
  field?:any
  tooltipInfo?: string;
};

const ActionButton: FC<PrimaryButtonProps> = ({
  onClick,
  disabled,
  variant = "contained",
  fullWidth = false,
  className,
  children,
  title,
  type,
  sx,
  tooltipInfo,
  field,
  ...rest
}) => {
  const handleClick=(e:any)=>{
    if(onClick){
      onClick('',field, false);
    }
  }
  const theme = useTheme();
  return (
    <ToolTip title={tooltipInfo}>
      <Button
        type={type}
        title={title}
        fullWidth={fullWidth}
        sx={{
            fontSize: '16px', '&.defaultButton': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
          },
          ':disabled':{
            opacity:0.6
          }
          ,
          ...sx
        }}
        className={`${variant === "contained" ? "defaultButton" : "textButton"
          } ${className}`}
        disabled={disabled}
        onClick={handleClick}
        variant={variant}
        {...rest}
      >
        {children}
      </Button>
    </ToolTip>
  );
};
export default ActionButton;
