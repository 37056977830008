import React from 'react';
import { Grid } from "@mui/material";
import Routes from "./routes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container className="main-body">
                <Routes />
            </Grid>
        </LocalizationProvider>
    );
}

export default App;
