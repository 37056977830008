import { AnyAction, configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { ThunkMiddlewareFor } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import authenticationSlice from "../reducer/authentication-slice";

const store: EnhancedStore<any, AnyAction, [ThunkMiddlewareFor<any>]> =
  configureStore({
    reducer: {
      authenticationSlice,
    },
  });

export default store;
