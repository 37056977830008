import React, { FC, MouseEvent, useState, useMemo, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./Verify.scss";
import {
    AlertColor,
    Box,
    Card,
    CircularProgress,
    Container,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import PrimaryButton from "../../../components/Button/PrimaryButton";
import Notification from "../../../components/Notification/Notification";
import { routes } from "../../../utils/constants/routes";
import { images } from "../../../utils/constants/images";
import PinInput from 'react-pin-input';
import Modal from "../../../components/modal/Modal";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";

const Verify: FC<any> = () => {
    const Navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    const [loading, setLoading] = useState<boolean>(false);
    const [openResendModal, setOpenResendModal] = useState(false);
    const [codeValue, setCodeValue] = useState("");
    const [message, setMessage] = useState<{
        display: boolean;
        severity: AlertColor | null;
        message: string;
    }>({
        display: false,
        severity: null,
        message: "",
    });

    const handleVerify = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            const body = { email: "giripraj864@gmail.com", code: codeValue, mailType: "FORGOT_PASSWORD" };
            const { status } = await axiosInstance.post(URLS.verify, body);
            if (status === 200) {
                setMessage({
                    display: true,
                    message: `Code has been Verified Successfully`,
                    severity: "success",
                });
                setTimeout(() => {
                    Navigate(routes.reset);
                }, 1000);
            }
        } catch (error: any) {
            setLoading(false);
            if (error.response) {
                setMessage({
                    display: true,
                    severity: "warning",
                    message: error.response.data.message,
                });
            }
        }
    };

    const handleResend = useCallback(async () => {
        try {
            const body = { email: "giripraj864@gmail.com" };
            const { status } = await axiosInstance.post(URLS.resend, body);
            if (status === 200) {
                setOpenResendModal(false);
                setMessage({
                    display: true,
                    message: `Code has been Re-sent Successfully`,
                    severity: "success",
                });
            }
        } catch (error: any) {
            setLoading(false);
            if (error.response) {
                setMessage({
                    display: true,
                    severity: "warning",
                    message: error.response.data.message,
                });
            }
        }
    }, []);

    const onChangeCodeValue = (value: any) => {
        setCodeValue(value);
    }

    useEffect(() => {
        const resendTimer = setInterval(() => {
            setOpenResendModal(true);
        }, 60000);

        return () => clearInterval(resendTimer);
    }, []);

    const ResendModalContent = useMemo(() => {
        return (
            <Grid container spacing={2}>
                <Grid item>
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 1,
                            fontSize: 24,
                            fontWeight: 600,
                            color: "#333333",
                        }}
                    >
                        Resend Code
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{
                            mb: 5,
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#727272",
                        }}
                    >
                        Didn’t get the code? No problem, we will send you another verification code.
                    </Typography>
                </Grid>
                <Grid item xs={6} className={"pt-0"}>
                    <PrimaryButton
                        variant="outlined"
                        onClick={() => {
                            setOpenResendModal(false);
                        }}
                    >
                        Cancel
                    </PrimaryButton>
                </Grid>
                <Grid item xs={6} className={"pt-0"}>
                    <PrimaryButton
                        disabled={loading}
                        variant="contained"
                        onClick={handleResend}
                        sx={{ color: "#ffffff", py: 0 }}
                    >
                        {loading && <CircularProgress sx={{ mr: 1 }} size={20} />} Resend It
                    </PrimaryButton>
                </Grid>
            </Grid>
        );
    }, [loading, handleResend]);

    const resendModal = useMemo(() => {
        return (
            <Modal
                open={openResendModal}
                setModalClose={setOpenResendModal}
                children={ResendModalContent}
                title={undefined}
                size={undefined}
                className={undefined}
                closeButton={false}
            />
        );
    }, [openResendModal, ResendModalContent]);

    const closeNotification = (value: boolean) => {
        setMessage({ ...message, display: value });
    };

    return (
        <Container
            sx={matches ? {} : { px: 0 }}
            className={"login-container"}
            maxWidth={false}
        >
            {resendModal}
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ minHeight: "calc(100vh)" }}
            >
                {message.display && (
                    <Notification
                        isOpen={message.display}
                        message={message.message}
                        severity={message.severity as AlertColor}
                        closeNotification={closeNotification}
                    />
                )}

                <Grid item xs={12} sm={8} lg={4}>
                    <Box textAlign="center" mb={3}>
                        <img src={images.logo} alt="logo" />
                    </Box>
                    <Card sx={{ p: matches ? 2 : 1 }}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            Verification Code
                        </Typography>
                        <form onSubmit={handleVerify as any}>
                            <PinInput
                                length={6}
                                initialValue={codeValue}
                                // secret
                                onChange={onChangeCodeValue}
                                type="custom"
                                inputMode="string"
                                style={{ padding: '10px', justifyContent: 'center', display: 'flex' }}
                                inputStyle={{ borderColor: 'red', borderRadius: '6px', }}
                                inputFocusStyle={{ borderColor: 'blue' }}
                                autoSelect={true}
                                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                            />
                            <PrimaryButton
                                disabled={loading}
                                sx={{ mt: 1, color: "#ffffff" }}
                                onClick={handleVerify}
                                type="submit"
                            >
                                {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
                                Verify
                            </PrimaryButton>
                        </form>
                    </Card>
                </Grid>

            </Grid>
        </Container >
    );
};
export default Verify;

