import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Avatar,
  styled,
  Drawer,
  CssBaseline,
  Toolbar,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  AppBarProps as MuiAppBarProps,
  Container,
  useTheme,
  IconButton,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Collapse from "@mui/material/Collapse";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LogoutIcon from "@mui/icons-material/Logout";
import "./AuthenticatedLayout.scss";
import config from "./config";
import { useLocation, useNavigate } from "react-router-dom";
import { URLS } from "../../utils/constants/urls";
import axiosInstance from "../../utils/axios";
import { clearStorage, fetchFromStorage } from "../../utils/storage";
import { identifiers } from "../../utils/constants/identifiers";
import { useDispatch, useSelector } from "react-redux";
import { setAuthentication } from "../../utils/redux";
import { routes } from "../../utils/constants/routes";
import { notificationCount, Privilege, setNotificationCount } from "../../utils/redux/reducer/authentication-slice";
import strings from "../../common/Translation/Translate";
import { images } from "../../utils/constants/images";
import NotificationWrapper from "../../components/NotificationInfo/NotificationWrapper";
import WebSocket from "../../components/WebSocket/WebSocket";
import { askForApproval } from "../../utils/validators/HelperFunctions";
import { Scrollbar } from 'react-scrollbars-custom';
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: -20,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AuthenticatedLayout: FC<{ Component: FC }> = ({ Component }) => {
  const theme = useTheme();
  const notification = useSelector(notificationCount) as any;
  const privilegeData = useSelector(Privilege);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [showSideBar, setShowSideBar] = useState<boolean>(true);
  const [key, setKey] = useState<string>();

  const handleProfile = () => {
    Navigate(routes.profile);
  };
  const handleLogout = async () => {
    const body: { refreshToken: string | null } = {
      refreshToken: fetchFromStorage(identifiers.refresh_token),
    };
    try {
      const { status }: { status: number } = await axiosInstance.post(
        URLS.logout,
        body
      );
      if (status === 200) {
        clearStorage();
        dispatch(setAuthentication(null));
      }
    } catch (e) {
      clearStorage();
      dispatch(setAuthentication(null));
    }
  };

  const getUser = useCallback(async () => {
    try {
      const { status, data } = await axiosInstance.get(URLS.userProfile);
      if (status === 200) {
        setUserData(data);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getUser();
    })();
  }, [getUser]);

  const menu = useMemo(() => {
    const handleExpand = (key: string) => {
      setKey(key);
      if (key === "21") {
        setOpen(!open);
      } else if (key === "7") {
        setOpen(!open);
      } else {
        setOpen(false);
      }
    };

    return (
      <List>
        {config(privilegeData, strings)
          .filter((item: any) => item)
          .map((menuItem: any, index: number) => (
            <div key={index}>
              <ListItem
                key={index}
                disablePadding
                sx={{
                  backgroundColor:
                    `/${location.pathname.split("/")[1]}` === menuItem.route
                      ? `${theme.palette.secondary.dark}1a`
                      : "inherit",
                  color:
                    `/${location.pathname.split("/")[1]}` === menuItem.route
                      ? theme.palette.secondary.dark
                      : "inherit",
                  borderRight:
                    `/${location.pathname.split("/")[1]}` === menuItem.route
                      ? `5px solid ${theme.palette.secondary.dark}`
                      : "none",
                }}
              >
                <ListItemButton
                  onClick={() => {
                    handleExpand(menuItem.key);
                    Navigate(menuItem.route);
                  }}
                  sx={{
                    color:
                      `/${location.pathname.split("/")[1]}` === menuItem.route
                        ? theme.palette.secondary.dark
                        : "#ffffff",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        `/${location.pathname.split("/")[1]}` === menuItem.route
                          ? theme.palette.secondary.dark
                          : "#ffffff",
                      fontSize: "10px",
                    }}
                  >
                    <menuItem.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={menuItem.name}
                    primaryTypographyProps={{
                      sx: { fontWeight: 600 },
                      fontSize: "0.9rem",
                    }}
                  />
                  {menuItem.key !== "7" ? (
                    ""
                  ) : !open && menuItem.key === "7" ? (
                    <KeyboardArrowRightIcon />
                  ) : (
                    <ExpandMore />
                  )}
                  {menuItem.key !== "21" ? (
                    ""
                  ) : !open && menuItem.key === "21" ? (
                    <KeyboardArrowRightIcon />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
              </ListItem>
              <Collapse
                in={open && menuItem.key === key}
                timeout="auto"
                unmountOnExit
              >
                <Divider />
                {menuItem?.children &&
                  menuItem?.children
                    .filter((item: any) => item)
                    .map((child: any, i: number) => (
                      <ListItem
                        key={i}
                        disablePadding
                        sx={{
                          backgroundColor:
                            `/${location.pathname.split("/")[1]}` ===
                              child.route
                              ? `${theme.palette.secondary.dark}1a`
                              : "inherit",
                          borderRight:
                            `/${location.pathname.split("/")[1]}` ===
                              child.route
                              ? `5px solid ${theme.palette.secondary.dark}`
                              : "none",
                        }}
                      >
                        <ListItemButton
                          onClick={() => {
                            Navigate(child.route);
                          }}
                          sx={{
                            color:
                              `/${location.pathname.split("/")[1]}` ===
                                child.route
                                ? theme.palette.secondary.dark
                                : "#ffffff",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              color:
                                `/${location.pathname.split("/")[1]}` ===
                                  child.route
                                  ? theme.palette.secondary.dark
                                  : "#ffffff",
                            }}
                          >
                            <child.icon />
                          </ListItemIcon>
                          <ListItemText
                            primaryTypographyProps={{
                              sx: { fontWeight: 600 },
                              fontSize: "0.9rem",
                            }}
                          >
                            {child.name}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
              </Collapse>
            </div>
          ))}
      </List>
    );
  }, [privilegeData, open, location.pathname, theme.palette.secondary.dark, key, Navigate]);
  const handleMessage = (msg: any, topics: any) => {
    if (topics === '/user/queue/notifications') {
      askForApproval(msg.title, '', identifiers.logoUrl);
      const counts = notification + msg?.newTaskCount
      dispatch(setNotificationCount(counts))
    }
  }
  return (
    <Container sx={{ display: "flex", height: "100%" }} maxWidth={false}>
      <WebSocket message={handleMessage} />
      <CssBaseline />
      <Drawer
        variant="persistent"
        open={showSideBar}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          height: "100%",
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            height: "100%",
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            paddingTop: "10px",
            '.MuiCollapse-root': { backgroundColor: theme.palette.primary.main, }
          }}
          height="100%"
        >
          <Scrollbar style={{ width: '100%', height: '96vh' }}>
            {menu}
          </Scrollbar>
        </Box>

      </Drawer>
      <Main open={showSideBar}>
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: theme.palette.secondary.light,
            boxShadow: "none",
            color: theme.palette.primary.main,
          }}
        >
          <Toolbar>
            <Box
              ml={showSideBar ? "255px" : "50px"}
              component="div"
              sx={{
                flexGrow: 25,
                color: theme.palette.primary.main,
                display: "flex",
                gap: "10px",
                transition: "0.2s",
              }}
            >
              <img
                height="32px"
                width="32px"
                src={
                  showSideBar ? images.sideBarCloseIcon : images.sideBarOpenIcon
                }
                alt="logo"
                onClick={() => setShowSideBar(!showSideBar)}
              />

              <Typography variant="h6" sx={{ mx: 2, fontWeight: 600 }}>
                {strings.project_title}
              </Typography>
            </Box>
            <NotificationWrapper color="red" />
            <Avatar
              sx={{
                marginLeft: 2,
                backgroundColor: theme.palette.primary.main,
                width: 30,
                height: 30,
              }}
              onClick={handleProfile}
              className={"cursor-pointer"}
            >
              <Typography variant="h6">
                {userData.firstName?.slice(0, 1)}
              </Typography>
            </Avatar>
            <Typography
              variant="body2"
              onClick={handleProfile}
              className={"cursor-pointer"}
              sx={{ mx: 2, fontWeight: 600 }}
            >
              {userData.firstName} {userData.lastName}
            </Typography>
            <IconButton onClick={handleLogout} aria-label="delete">
              <LogoutIcon />
            </IconButton>
          </Toolbar>

          {/* <InfoModal/> */}
        </AppBar>
        <Toolbar />
        <Component />
      </Main>
    </Container>
  );
};

export default AuthenticatedLayout;
