import React, { useState, FC, ChangeEvent, useEffect } from "react";
import { validateInput } from "../../utils/validators/Validators";
import { TextFieldProps, Typography, FormControl, FormHelperText, Stack, IconButton, Link } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FormValidators } from "../Form";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";

interface OwnProps {
  leftIcon?: string;
  inputValue?: any;
  field?: string;
  typeValue?: string;
  textChange?: (
    value: string,
    field: string,
    error?: { error: boolean; message: string }
  ) => void;
  validators?: FormValidators[];
  hasError?: boolean;
  fieldError?: boolean | string;
  accept?: string;
}

const File: FC<OwnProps & TextFieldProps> = ({
  hasError,
  validators,
  textChange,
  field,
  inputValue,
  fieldError,
  label,
  accept = '',
}) => {
  const [error, setError] = useState<{
    error: boolean;
    message: string;
  } | null>(null);
  const param = useParams();
  const [fileName, setFileValue] = useState<any>(inputValue);
  useEffect(() => {
    setFileValue(inputValue)
  }, [inputValue])
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Array.from(e.target.files as FileList).map((file: any) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      }),
    );
    if (value) {
      setFileValue((files: any) => [...files, ...Array.from(value as any)]);
      const inputError = validateInput(validators as FormValidators[], value as any);
      setError(inputError);
      if (textChange) {
        textChange([...fileName, ...Array.from(value as any)] as any, field as string, inputError);
      }
    }
  };
  const deletFiles = (file: any) => {
    const filter = fileName.filter((item: any) => item.name !== file.name);
    setFileValue(filter)
  }
  return (
    <FormControl>
      {label && <Typography variant="body2" mb={1}>{label}</Typography>}
      <Stack direction='column'>
        <Button disabled={param.id as any} sx={{ width: 200 }} variant="contained" component="label" endIcon={<AttachFileIcon />}>
          {/* <IconButton sx={{ width: 40 }} color="primary" aria-label="upload picture" component="label"> */}
          <input multiple onChange={handleChange} hidden accept={accept} type="file" />
          Add attachment
        </Button>
        {/* {(fileName?.name || fileName[0]?.fileName) && */}
        {!!fileName && Array.from(fileName)?.map((file: any, index: number) => (
          <Stack key={index} alignItems="center" direction='row'>
            <Link download href={file.preview} variant="body2">
              <Stack my={1} minWidth='100%' sx={{ border: '1px solid #ddd' }} p={1} direction='row'>
                {/* <DescriptionIcon sx={{ mr: 1 }} />  */}
                {file?.name || file.name}
              </Stack>
            </Link>
            {!param.id && <IconButton onClick={() => deletFiles(file)} aria-label="delete" size="small">
              <DeleteIcon fontSize="inherit" />
            </IconButton>}
          </Stack>
          )
        )}
        {/* } */}
      </Stack>
      <FormHelperText>
        {error && error.error
          ? error.message
          : !error && hasError && fieldError
            ? "This field is required"
            : ""}
      </FormHelperText>
    </FormControl>
  );
};
export default File;
