import { Validators } from "../../../../../../utils/validators";
import { FormModel } from "../../../../../../components/Form";
export const AddEditKeyAttributesForm = (strings: any): FormModel[] => {
  return [
    {
      label: `${strings.key_attributes_table_column_Ticket_name} *`,
      value: "",
      size: "medium",
      autoFocus: true,
      type: "text",
      typeValue: "text",
      variant: "outlined",
      placeholder: "",
      field: "keyAttribute",
      validators: [
        { check: Validators.required, message: strings.requiredText },
      ],
      responsive: { xs: 4 },
      required: true,
    },
  ];
};
